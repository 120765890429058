import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AdminLogin() {
  const [isPassword, setIsPassword] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
  });
  const history = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${apiUrl}adminlogin.php`;

    try {
      const { username, password } = userInfo;

      const response = await axios.get(
        url,
        {
          params: {
            username: username,
            password: password,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          maxContentLength: Infinity,
        }
      );

      const data = response.data;

      localStorage.setItem("spanAdminToken", data.token);
      history("/admin");
    } catch (error) {
      window.alert("Incorrect Credentials"); // Added user feedback
    }
  };

  return (
    <form autoComplete="off" className="SignIn" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          name="username"
          value={userInfo.username}
          onChange={handleChange}
        />
        <div className="passwordcontainer">
          <label htmlFor="password">Password</label>
          <input
            type={isPassword ? "password" : "text"}
            id="password"
            name="password"
            value={userInfo.password}
            onChange={handleChange}
          />
          {isPassword ? (
            <i
              className="fa-regular fa-eye"
              onClick={() => setIsPassword(!isPassword)}
            ></i>
          ) : (
            <i
              className="fa-regular fa-eye-slash"
              onClick={() => setIsPassword(!isPassword)}
            ></i>
          )}
        </div>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
}

export default AdminLogin;
