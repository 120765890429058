import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function CreateCase(props) {
  const { username } = useParams();

  const [formData, setFormData] = useState({
    CaseNo: "",
    CaseTitle: "",
    Date: "",
    Result: "Yet to Come",
    LawyerName: "",
    ClientName: username,
    CurrentStatus: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}casecr.php`;
    let encodedParams = "";

    // Iterate over formData object and encode key-value pairs
    for (const [key, value] of Object.entries(formData)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      encodedParams += `${encodedKey}=${encodedValue}&`;
    }

    // Remove the trailing "&" character
    encodedParams = encodedParams.slice(0, -1);

    axios
      .get(`${url}?${encodedParams}`)
      .then((response) => {
        // Optionally, you can reset the form data after successful submission
        const defaultsteps = [
          {
            status_step_title: "Detection",
            status_step_value: 0,
            step_remark: "Initial detection of case.",
            step_number: 1,
          },
          {
            status_step_title: "Validation",
            status_step_value: 1,
            step_remark: "Validation of case details.",
            step_number: 2,
          },
          {
            status_step_title: "Investigation",
            status_step_value: 1,
            step_remark: "In-depth investigation process.",
            step_number: 3,
          },
          {
            status_step_title: "Enforcement",
            status_step_value: 1,
            step_remark: "Implementation of enforcement measures.",
            step_number: 4,
          },
          {
            status_step_title: "Post Enforcement",
            status_step_value: 1,
            step_remark: "Actions taken after enforcement.",
            step_number: 5,
          },
          {
            status_step_title: "Assess Monitor and ROI Calculations",
            status_step_value: 1,
            step_remark: "Assessment, monitoring, and ROI calculations.",
            step_number: 6,
          },
          {
            status_step_title: "Case Complete",
            status_step_value: 1,
            step_remark: "Case successfully completed.",
            step_number: 7,
          },
        ];

        const createStatus = async () => {
          try {
            const statusresponce = await axios.get(
              `${process.env.REACT_APP_API_URL}casereg.php?title=${
                formData.CaseTitle
              }&value=0&steps=${JSON.stringify(defaultsteps)}&caseNo=${
                formData.CaseNo
              }`
            );
            return statusresponce;
          } catch (error) {}
        };

        const statusdata = createStatus();
        setFormData({
          CaseNo: "",
          CaseTitle: "",
          Date: "",
          Result: "Yet to Come",
          LawyerName: "",
          ClientName: "",
          CurrentStatus: "",
        });
        window.alert("Case Created Successfully");
        setisCreateCaseActive(false);
        fetchCases();
      })
      .catch((error) => {
        window.alert("Case Creation Failed, Please Try Again Later!");
      });
  };

  const { setisCreateCaseActive, isCreateCaseActive, fetchCases } = props;

  return (
    <div id="CreateCase">
      <form autoComplete="off" id="CaseCreateForm" onSubmit={handleSubmit}>
        <span
          onClick={() => setisCreateCaseActive(!isCreateCaseActive)}
          id="crossbtn"
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        <div>
          <label>Case Number:</label>
          <input
            type="text"
            name="CaseNo"
            value={formData.CaseNo}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Case Title:</label>
          <input
            type="text"
            name="CaseTitle"
            value={formData.CaseTitle}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Date:</label>
          <input
            type="date"
            name="Date"
            value={formData.Date}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Case Manager:</label>
          <input
            type="text"
            name="LawyerName"
            value={formData.LawyerName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Client Name:</label>
          <input
            readOnly
            type="text"
            name="ClientName"
            value={formData.ClientName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Current Status:</label>
          <input
            type="text"
            name="CurrentStatus"
            value={formData.CurrentStatus}
            onChange={handleChange}
          />
        </div>
        <input type="submit" value={"Submit"} />
      </form>
    </div>
  );
}

export default CreateCase;
