import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Loader from "./Loader";

function Contact() {
  const observedElements = useRef([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    preferredContact: "email",
    hearAboutUs: "",
    receiveNewsletter: "yes",
  });
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = "scale(1)";
          } else {
            entry.target.style.transform = "scale(0.95)";
          }
        });
      },
      { threshold: 0.1 }
    );

    // Ensure that observedElements.current has elements and they are valid before observing
    if (observedElements.current.length > 0) {
      observedElements.current.forEach((el) => {
        if (el && el instanceof Element) {
          observer.observe(el);
        }
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [observedElements.current]); // Include observedElements.current in dependency array

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    // Check if any field is empty
    for (const field in formData) {
      if (formData[field] === "") {
        alert("Please fill in all fields");
        return;
      }
    }

    try {
      setisloading(true);
      // Send email to user
      await sendEmail(
        formData.email,
        "Thank you for your response!",
        `
        <p>Dear ${formData.name},</p>
        <p>We have received your message and will get back to you shortly.</p>
        <p>Thank you for contacting us!</p>
      `
      );

      // Send email to admin
      await sendEmail(
        process.env.REACT_APP_ADMIN_EMAIL,
        "New Contact Form Submission",
        `
        <h1>New Contact Form Submission</h1>
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Phone:</strong> ${formData.phone}</p>
        <p><strong>Subject:</strong> ${formData.subject}</p>
        <p><strong>Message:</strong> ${formData.message}</p>
        <p><strong>Preferred Contact Method:</strong> ${formData.preferredContact}</p>
        <p><strong>How did you hear about us?:</strong> ${formData.hearAboutUs}</p>
        <p><strong>Receive Newsletter:</strong> ${formData.receiveNewsletter}</p>
      `
      );
      setisloading(false);

      setSubmissionMessage("Mail sent successfully!");
      alert("Mail sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      setisloading(false);
      setSubmissionMessage("An error occurred while sending the email.");
    }
  };

  const sendEmail = async (receiverMail, subject, body) => {
    try {
      const response = await axios.post(process.env.REACT_APP_MAIL_API, {
        receiverMail,
        subject,
        body,
      });

      if (response.status !== 200) {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isloading ? <Loader /> : <></>}
      <div className="Contact">
        <h1 ref={(el) => observedElements.current.push(el)}>CONTACT US</h1>
        <div>
          <div
            className="adress"
            ref={(el) => observedElements.current.push(el)}
          >
            <h2>Get in Touch</h2>
            <a
              href="https://maps.app.goo.gl/NZDWNnyXoAtDraxy6"
              target="_blank"
              rel="noreferrer"
            >
              New Delhi, India
            </a>
            <p>
              Office No. 516, Dwarka City Centre, Sector 12, Dwarka, New Delhi
              110075, INDIA
            </p>
            <span>+91-829-12345-03</span>
            <a
              href="https://maps.app.goo.gl/ndbhqNoEuw3Yui3m6"
              target="_blank"
              rel="noreferrer"
            >
              Mumbai, India
            </a>
            <p>
              {" "}
              902, Embassy Centre, Jamnalal Bajaj Road, Nariman Point, Mumbai
              400021, INDIA{" "}
            </p>
            <span> +91-829-12345-02 </span>
            <a
              href="https://maps.app.goo.gl/mb7s738s46kWUBB26"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Singapore{" "}
            </a>
            <p> 46 Kim Yam Road, #01-06 The Herencia, SINGAPORE - 239351 </p>
            <span> +65-98635879 </span>
            <a
              href="https://maps.app.goo.gl/nYwvvBELGR1UDBU16"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Dhaka, Bangladesh
            </a>
            <p>
              DARUS SALAM ARCADE (3rd Floor) 14, Purana Paltan, Dhaka -1000,
              BANGLADESH
            </p>
            <span> +880 1894-697179</span>
          </div>
          <div className="form" ref={(el) => observedElements.current.push(el)}>
            <div>
              <label htmlFor="">Name</label>
              <input
                type="text"
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="">Phone No</label>
              <input
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div id="message">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                name="subject"
                id="subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <label htmlFor="">Message</label>
              <textarea
                name="message"
                cols="30"
                rows="10"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <label>Preferred Method of Contact:</label>
              <div>
                <input
                  type="radio"
                  name="preferredContact"
                  id="is-preferred-method-email"
                  value="email"
                  checked={formData.preferredContact === "email"}
                  onChange={handleChange}
                />
                <label htmlFor="is-preferred-method-email">Email</label>
                <input
                  type="radio"
                  name="preferredContact"
                  id="is-preferred-method-phone"
                  value="phone"
                  checked={formData.preferredContact === "phone"}
                  onChange={handleChange}
                />
                <label htmlFor="is-preferred-method-phone">Phone</label>
              </div>
              <label htmlFor="howdidyouhearaboutus">
                How did you hear about us?
              </label>
              <textarea
                name="hearAboutUs"
                cols="30"
                rows="10"
                id="howdidyouhearaboutus"
                value={formData.hearAboutUs}
                onChange={handleChange}
              ></textarea>
              <label>
                Would you like to receive updates and newsletters from us?
              </label>
              <div>
                <input
                  type="radio"
                  name="receiveNewsletter"
                  id="wanttoreceivenewsletter-yes"
                  value="yes"
                  checked={formData.receiveNewsletter === "yes"}
                  onChange={handleChange}
                />
                <label htmlFor="wanttoreceivenewsletter-yes">Yes</label>
                <input
                  type="radio"
                  name="receiveNewsletter"
                  id="wanttoreceivenewsletter-no"
                  value="no"
                  checked={formData.receiveNewsletter === "no"}
                  onChange={handleChange}
                />
                <label htmlFor="wanttoreceivenewsletter-no">No</label>
              </div>
              <p>
                By submitting this form, you agree to our Privacy Policy and
                Terms of Service.
              </p>
            </div>
            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
            <p>
              To Schedule a meeting please click on this link{" "}
              <a
                href="https://meetings.hubspot.com/admin-span-ip"
                target="_blank"
                rel="noreferrer"
              >
                schedule meet
              </a>
            </p>
          </div>
        </div>
        <h1 ref={(el) => observedElements.current.push(el)}>Here we are:</h1>
        <div className="map" ref={(el) => observedElements.current.push(el)}>
          <iframe
            title="ouraddress"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7006.7761096230115!2d77.04062159565707!3d28.588133123578896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b55fbe6ee21%3A0x7c08ef8aa9430a1a!2sSPAN%20Consulting!5e0!3m2!1sen!2sin!4v1707122798541!5m2!1sen!2sin"
            width={600}
            height={450}
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
