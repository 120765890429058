import React, { useEffect, useRef, useState } from "react";

function IiliteTrade() {
  const [isVisible, setIsVisible] = useState(false);

  const h1Ref = useRef(null);
  const cardsRef = useRef(null);
  const [animationcount, setanimationcount] = useState(0);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      setIsVisible(entries[0].isIntersecting);
    }, options);

    if (h1Ref.current && cardsRef.current) {
      observer.observe(h1Ref.current);
      observer.observe(cardsRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (animationcount < 2) {
      const h1Element = h1Ref.current;
      const cardsElement = cardsRef.current;

      if (isVisible) {
        h1Element.style.transform = "translateY(0px)";
        cardsElement.style.transform = "translateY(0px)";
      } else {
        h1Element.style.transform = "translateY(5em)";
        cardsElement.style.transform = "translateY(5em)";
      }
      setanimationcount(() => animationcount + 1);
    }
  }, [isVisible]);

  return (
    <div id="IiliteTrade">
      <div>
        <h1 ref={h1Ref}>Illicit Trade Overview</h1>
        <h3>What is Counterfeit?</h3>
        <p>
          A counterfeit product is designed, branded, and packaged to closely
          resemble a genuine item. This imitation is created without the
          authorization of the original brand owner, intending to deceive
          consumers into thinking they are purchasing the authentic product.
        </p>
        <div className="lilite-cards" ref={cardsRef}>
          <div>
            Counterfeit goods pose a significant global challenge, affecting
            consumers and brand owners across all industries. The estimated
            value of counterfeit and pirated goods, including digital piracy, in
            2022 is projected to reach between $1.90 trillion and $2.81
            trillion. This vast amount highlights the issue's extensive reach,
            infiltrating markets worldwide and causing widespread economic and
            social repercussions.
          </div>
          <div>
            Recent data and forecasts reveal potential growth in domestic
            production and consumption of counterfeit goods. By 2022, the value
            of these goods is expected to range from $524 billion to $959
            billion. Projections for digital piracy, based on its share of total
            counterfeiting or its growth with global IP traffic, indicate that
            pirated movies, music, and software could value between $384 billion
            and $856 billion. These numbers emphasize the significant economic
            impact of digital piracy.
          </div>
          <div>
            The rise of online counterfeiting has exponentially increased its
            impact. Criminals exploit the Internet's anonymity, using the Dark
            Web to conceal their identities and IP addresses. This global reach
            allows them to bypass national law enforcement limits, forcing brand
            owners to take legal action in multiple jurisdictions. Additionally,
            counterfeiters often display genuine goods online but ship fake
            products to consumers, making it difficult for brand owners to
            identify fraudulent sites without incurring significant costs. This
            complexity is compounded by organized criminal networks operating
            numerous sites selling counterfeit goods.
          </div>
        </div>
      </div>
    </div>
  );
}

export default IiliteTrade;
