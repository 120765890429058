import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function NavbarTraningMobile(props) {
  const { setisBurgerActive, setisLevel2Active } = props;
  const [healthcareInstitutions, setHealthcareInstitutions] = useState(false);
  const [educationalInstitutions, setEducationalInstitutions] = useState(false);
  const [corporate, setCorporate] = useState(false);
  return (
    <div className="accordion-content">
      <Link
        onClick={() => {
          setisBurgerActive((prevState) => !prevState);
          setisLevel2Active(false);
        }}
        to="/traning/overview"
      >
        Overview
      </Link>
      <Link
        onClick={() => {
          setHealthcareInstitutions((prevState) => !prevState);
          setEducationalInstitutions(false);
          setCorporate(false);
        }}
      >
        Healthcare Institutions
      </Link>

      {healthcareInstitutions ? (
        <>
          <Link
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              setisBurgerActive((prevState) => !prevState);
              setisLevel2Active(false);
            }}
            to="/services/traning/healthcareinstitutions/programme"
          >
            Programme
          </Link>
          <Link
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              setisBurgerActive((prevState) => !prevState);
              setisLevel2Active(false);
            }}
            to="/services/traning/healthcareinstitutions/mlc"
          >
            Medico Legal Awareness Programme
          </Link>
        </>
      ) : (
        <></>
      )}

      <Link
        onClick={() => {
          setHealthcareInstitutions(false);
          setEducationalInstitutions((prevState) => !prevState);
          setCorporate(false);
        }}
      >
        Educational Institutions
      </Link>

      {educationalInstitutions ? (
        <>
          <Link
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              setisBurgerActive((prevState) => !prevState);
              setisLevel2Active(false);
            }}
            to="/services/traning/iprinschool"
          >
            IPR in Schools
          </Link>
          <Link
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              setisBurgerActive((prevState) => !prevState);
              setisLevel2Active(false);
            }}
            to="/services/traning/educationalinstitutions/softskills"
          >
            Soft Skills
          </Link>
        </>
      ) : (
        <></>
      )}

      <Link
        onClick={() => {
          setHealthcareInstitutions(false);
          setEducationalInstitutions(false);
          setCorporate((prevState) => !prevState);
        }}
      >
        Corporate
      </Link>
      {corporate ? (
        <>
          <Link
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              setisBurgerActive((prevState) => !prevState);
              setisLevel2Active(false);
            }}
            to="/services/traning/corporate/softskills"
          >
            Soft Skills
          </Link>
        </>
      ) : (
        <></>
      )}

      <Link
        onClick={() => {
          setisBurgerActive((prevState) => !prevState);
          setisLevel2Active(false);
        }}
        to="/traning/lawenforcementagenciestraining"
      >
        Law Enforcement Agencies (LEA) Training
      </Link>
      <Link
        onClick={() => {
          setisBurgerActive((prevState) => !prevState);
          setisLevel2Active(false);
        }}
        to="/traning/customtraning"
      >
        Customs Training
      </Link>
      <Link
        onClick={() => {
          setisBurgerActive((prevState) => !prevState);
          setisLevel2Active(false);
        }}
        to="/traning/externalandinternalstackholdertraning"
      >
        External and Internal Stakeholder Training
      </Link>
    </div>
  );
}

export default NavbarTraningMobile;
