import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import FileRecordUpload from "./UploadFile";
import axios from "axios";

function AdminFilesView() {
  const [isUploadFileActive, setisUploadFileActive] = useState(false);
  const [filelist, setfilelist] = useState([]);
  const { caseno } = useParams();

  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}filefetch.php?CaseNo=${caseno}`
      );
      setfilelist(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchFiles();
  }, [caseno]);

  const oneRow = (file, index) => {
    return (
      <li key={index}>
        <div className="srdiv">{index + 1}</div>
        <div>{file.Filename}</div>
        <div>{file.TimeStamp}</div>
        <div>{file.Uploader}</div>
        <div>{file.Size}</div>
        <a href={file.Url} download>
          <i className="fa-solid fa-download"> </i>
        </a>
      </li>
    );
  };

  return (
    <div id="UserCheckMyfiles">
      {isUploadFileActive ? (
        <FileRecordUpload
          isUploadFileActive={isUploadFileActive}
          setisUploadFileActive={setisUploadFileActive}
          fetchFiles={fetchFiles}
        />
      ) : (
        <></>
      )}
      <div className="filesoptions">
        <Link to="/user/checkmyfiles" className="backoption">
          <i className="fa-solid fa-angle-left"></i>
          <h1>Case no : {caseno}</h1>
        </Link>

        <button
          onClick={() => setisUploadFileActive(!isUploadFileActive)}
          style={{ backgroundColor: "#58e04c" }}
        >
          Upload File
        </button>
      </div>
      <ul>
        <li className="headli">
          <div className="srdiv">Sr.no</div>
          <div className="srdiv">Name</div>
          <div className="srdiv">Upload Date</div>
          <div className="srdiv">Uploaded By</div>
          <div className="srdiv">Size</div>
          <div></div>
        </li>
        {filelist.map((file, index) => oneRow(file, index))}
      </ul>
    </div>
  );
}

export default AdminFilesView;
