import React from "react";

function Programme() {
  const Pdata = [
    {
      first: "Aanya",
      second:
        "Optimized Nurse Performance: Elevating Patient Experience and Resolving Family Conflicts",
    },
    {
      first: "Sahridya",
      second:
        "Frontline Excellence: Elevating Institutional Impressions, Enhancing Patient Experience, and Minimizing Family Conflicts and Staff Queries",
    },
    {
      first: "Medi-procure Protect",
      second:
        "Identifies risk associated with procurement in healthcare and ways to mitigate it",
    },
    { first: "Tech-Hunt", second: "To establish presence on e-platform" },
    {
      first: "Sanchayak",
      second:
        "Nurturing Nursing Leadership: Empowering Transitioning Nurses to Excel as Leaders and Foster Nursing Excellence",
    },
    {
      first: "Antramanchan",
      second:
        "Empowering Personal Effectiveness: Cultivating Professionalism, Elevating Emotional Intelligence, and Strengthening Communication for Enhanced Engagement",
    },
    {
      first: "Sarthak",
      second:
        "While healthcare professionals refine their medical competencies, there is often a neglect of interpersonal skills development. Prioritize efforts towards enhancing people skills for comprehensive care.",
    },
    {
      first: "Jeevanraksha",
      second:
        "Enhancing Patient and Family Counselling: Strengthening Medication Understanding and Adherence, Elevating Prescription Dispensing Proficiency, and Promoting Seamless Collaboration",
    },
    {
      first: "Yantrik",
      second:
        "Empowering Technician Excellence: Elevating Personal Effectiveness, Enhancing Patient Experience, Minimizing Family Conflicts, and Fostering Teamwork and Collaboration",
    },
    {
      first: "Nivesh",
      second:
        "Empower doctors with financial planning knowledge for informed decisions and financial well-being.",
    },
    {
      first: "Zenfrost",
      second:
        "Tailored for Doctors: Practical Strategies to Mitigate Stress, Attain Work-Life Balance, and Optimize Resource Allocation",
    },
    {
      first: "Compassion Connect",
      second:
        "Focusing on Patient Emotional Well-being: Enhancing Doctor Empathy, Compassion, and Therapeutic Communication",
    },
    {
      first: "Saathi Samridhi",
      second:
        "Transforming Organizational Culture: Cultivating Trust and Collaboration Among Teams to Foster Mutual Support",
    },
    {
      first: "Next Innings",
      second:
        "Empowering Healthcare Leaders: Breaking Organizational Inertia, Driving Transformational Change, Strengthening Relationships, Achieving Peak Performance",
    },
    {
      first: "CertSafe",
      second:
        "Awareness on certification requirements and key points for patient safety",
    },
    {
      first: "Pralekh",
      second:
        "Enhancing Documentation and Monitoring Through Audit Focus Areas and Staff Training",
    },
    {
      first: "Quality Care Leadership",
      second:
        "Tool enables nurse managers to uphold quality care standards, leading to heightened job satisfaction and enhanced productivity",
    },
    {
      first: "Supply Watch",
      second:
        "Enhances vendor selection criteria definition, streamlines discrepancy resolution, and standardizes indenting and storage practices",
    },
    {
      first: "Patient Safety Navigator",
      second: "Guiding Implementation of Global Safety Measures",
    },
    {
      first: "Medi Voyage",
      second:
        "Enhancing inflow of patients to receive medical treatment or procedures, and also enhance patient safety and care",
    },
    {
      first: "Safety Certify",
      second: "Staff Proficiency in Patient Safety Protocols and Certification",
    },
    {
      first: "Risk Assessment and Management Tool",
      second: "Identify risk and develop strategies to mitigate",
    },
    {
      first: "Risk Mitigation Workshop",
      second:
        "Identify common risk areas and develop strategy to mitigate the risk",
    },
  ];

  const oneProgramme = (data, index) => {
    return (
      <div className="flip-card" key={`${index}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <p className="title">{data.first.toUpperCase()}</p>
          </div>
          <div className="flip-card-back">
            <p>{data.second}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="Programme">
      <div className="templatebrd">
        <h2>
          SERVICES / LEADERSHIP & DEVELOPMENT / HEALTHCARE INSTITUTION /
          PROGRAMME
        </h2>
        <h1>PROGRAMME</h1>
      </div>
      <div className="programmecards">
        {Pdata.map((data, index) => oneProgramme(data, index))}
      </div>
    </div>
  );
}

export default Programme;
