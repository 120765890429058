import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const sections = [
  { name: "User", route: "/admin/users" },
  { name: "Admin", route: "/admin/admins" },
  { name: "Media", route: "/admin/media" },
  // Add more sections here as needed
];

function AdminDashboard() {
  const navigate = useNavigate();

  const loc = useLocation();
  const handlePanelSwitch = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (sections.length > 0 && loc.pathname === "/admin") {
      navigate(sections[0].route);
    }
  }, []);

  return (
    <>
      <div className="user-adminswitch">
        {sections.map((section, index) => (
          <button key={index} onClick={() => handlePanelSwitch(section.route)}>
            {section.name}
          </button>
        ))}
      </div>
      <Outlet />
    </>
  );
}

export default AdminDashboard;
