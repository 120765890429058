import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

function User() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const token = localStorage.getItem("spanUserToken") || "";
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}userdatafetch.php?token=${encodeURIComponent(token)}`
        );
        setData(response.data.data);
        localStorage.setItem("spanUserName", data.username);

        navigate("/user/checkmyfiles");
      } catch (error) {
        navigate("/login");
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.setItem("spanUserToken", "");
    localStorage.setItem("spanUserName", "");
    navigate("/");
  };

  return (
    <div id="User">
      <div className="profile-div">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/profil_photo.webp"}
          alt=""
        />
        <div>
          <p>
            <span>Name:</span> {decodeURIComponent(data.name)}
          </p>
          <p>
            <span>Username:</span> {decodeURIComponent(data.username)}
          </p>
          <p>
            <span>Email:</span> {decodeURIComponent(data.email)}
          </p>
          <p>
            <span>Company Name:</span> {decodeURIComponent(data.company_name)}
          </p>
        </div>
      </div>
      <div className="access-div">
        <div className="nav-container">
          <Link to={"checkmyfiles"}>Check Your Files</Link>
          <Link to={"status"}>Status</Link>
          <Link to={"contactus"}>Contact Us</Link>
          <Link to={"settings"}>Settings</Link>
          <a
            href="https://meetings.hubspot.com/admin-span-ip"
            target="_blank"
            rel="noreferrer"
          >
            Schedule Meeting
          </a>
          <Link className="logout-btn" to={"/"} onClick={handleLogout}>
            Log Out
          </Link>
        </div>
        <Outlet data={data} />
      </div>
    </div>
  );
}

export default User;
