import React, { useEffect, useRef, useState } from "react";
import {
  Shield,
  Package,
  FileSearch,
  School,
  ShieldCheck,
  ScrollText,
  BarChart2,
  FileText,
} from "lucide-react";

function OverviewOfSpan() {
  const [isVisible, setIsVisible] = useState(false);
  const [animationcount, setanimationcount] = useState(0);

  const OurOfferingData = [
    {
      title: "Product Security Investigations & Enforcement",
      content:
        "PSI&E through civil, criminal, and administrative measures, leveraging law enforcement and regulatory agencies",
      icon: <Shield />,
    },
    {
      title: "Supply Chain Security and Integrity",
      content:
        "Supply chain security and integrity programs, risk assessments, and investigations",
      icon: <Package />,
    },
    {
      title: "Legal and Business Risk Consulting",
      content:
        "Consulting, assessments, and development of risk mitigation plans",
      icon: <FileSearch />,
    },
    {
      title: "Training Programs for Stakeholders",
      content:
        "Training programs for LEA, customs, external and internal stakeholders",
      icon: <School />,
    },
    {
      title: "Customs Recordation of IPR",
      content: "Customs recordation of intellectual property rights (IPR)",
      icon: <ShieldCheck />,
    },
    {
      title: "IP Registration and Prosecution",
      content: "IP registration and prosecution services",
      icon: <ScrollText />,
    },
    {
      title: "Market Research",
      content: "Comprehensive primary and secondary market research",
      icon: <BarChart2 />,
    },
    {
      title: "Audits and Litigation",
      content: "Audits and litigation support services",
      icon: <FileText />,
    },
  ];

  const overviewRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      setIsVisible(entries[0].isIntersecting);
    }, options);

    if (overviewRef.current) {
      observer.observe(overviewRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (animationcount < 2) {
      const overviewElement = overviewRef.current;

      if (isVisible) {
        overviewElement.style.transform = "translateX(0px)";
      } else {
        overviewElement.style.transform = "translateX(-5em)";
      }
      setanimationcount(() => animationcount + 1);
    }
  }, [isVisible]);
  return (
    <div id="overviewOfSpan">
      <div ref={overviewRef}>
        <h1> OVERVIEW OF SPAN CONSULTING</h1>
        <p>
          SPAN Consulting is a leading provider of professional services,
          offering specialized brand protection, legal, investigative, business
          risk advisory, and strategic consulting services to our valued clients
          across South Asia and Southeast Asia. With offices in Singapore,
          India, and Bangladesh, we have established ourselves as a trusted
          partner in the region.
        </p>
        <p>
          We boast extensive experience and take great pride in delivering a
          comprehensive range of services, encompassing intellectual property
          (IP) matters such as trademarks, copyrights, patents, and designs, as
          well as confidential information, trade secrets, healthcare regulatory
          laws, media, entertainment, sports laws, and digital and IT laws
        </p>
        <p>
          Driven by technology, we have developed a suite of proprietary tools,
          toolkits, and modules in-house, tailored to address various needs such
          as brand protection, case management, budget management, and ROI
          calculation. Our Brand Protection: Total Legal & Business Solutions
          (TLBS) practice is meticulously crafted to address concerns regarding
          counterfeiting, product diversion, product tampering, and IPR
          infringement. These solutions are designed and executed to fulfill our
          client's primary objectives of ensuring patient/consumer safety,
          safeguarding reputation and trust, and enhancing revenue streams.
        </p>
      </div>
      <div className="ourofferingsection">
        {OurOfferingData.map((item, index) => (
          <div key={index} className="ouroffering">
            <div>
              <span>{item.icon}</span>
              <div className="vline"></div>
            </div>
            <div>
              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OverviewOfSpan;
