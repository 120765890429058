import React, { useState } from "react";
import axios from "axios";
import Loader from "./Loader";

function BrandPrtForm1() {
  const [formData, setFormData] = useState({
    name: "",
    org: "",
    location: "",
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission and page refresh

    setIsSubmitting(true);

    try {
      // Send email to user
      await sendEmail(
        formData.email,
        "Thank you for your response!",
        `
        <p>Dear ${formData.name},</p>
        <p>We have received your message and will get back to you shortly.</p>
        <p>Thank you for contacting us!</p>
      `
      );

      // Send email to admin
      await sendEmail(
        process.env.REACT_APP_ADMIN_EMAIL,
        "New Brand Protection Form Submission",
        `
        <h1>New Brand Protection Form Submission</h1>
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Company/Organization:</strong> ${formData.org}</p>
        <p><strong>Location:</strong> ${formData.location}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
      `
      );

      setIsSubmitting(false);
      setSubmissionMessage("Mail sent successfully!");
      alert("Mail sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false);
      setSubmissionMessage("An error occurred while sending the email.");
    }
  };

  const sendEmail = async (receiverMail, subject, body) => {
    try {
      const response = await axios.post(process.env.REACT_APP_MAIL_API, {
        receiverMail,
        subject,
        body,
      });

      if (response.status !== 200) {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <div className="templatebrd">
        <h2>SPAN / BRAND PROTECTION / BRAND PROTECTION RISK ASSESSMENT</h2>
        <h1>BRAND PROTECTION RISK ASSESSMENT</h1>
      </div>
      <main className="container" style={{ padding: "10vh 20px" }}>
        <div className="p-5">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div style={{ maxWidth: "700px" }}>
              <h2 className="text-center mt-3">
                Brand Protection Risk Assessment
              </h2>
              <form acceptCharset="utf-8" onSubmit={handleSubmit}>
                <div className="form-group mb-2">
                  <label className="form-label">Your Name *</label>
                  <input
                    className="form-control"
                    defaultValue=""
                    name="name"
                    placeholder="Enter your name"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group  mb-2">
                  <label className="form-label">
                    Company/Organization Name: *
                  </label>
                  <input
                    className="form-control"
                    defaultValue=""
                    name="org"
                    placeholder="Enter Organisation name"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group  mb-2">
                  <label className="form-label">Location: *</label>
                  <input
                    className="form-control"
                    defaultValue=""
                    name="location"
                    placeholder="Enter Location"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group  mb-2">
                  <label className="form-label">Email *</label>
                  <input
                    className="form-control"
                    defaultValue=""
                    name="email"
                    placeholder="Enter your email"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group text-end ">
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "#e11d48",
                    }}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Start
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      {submissionMessage && <p>{submissionMessage}</p>}
    </>
  );
}

export default BrandPrtForm1;
