import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function FileRecordUpload(props) {
  const { username, caseno } = useParams();
  const currentDate = new Date();

  // Get date components
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Get time components
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  // Construct the date and time string
  const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  const [formData, setFormData] = useState({
    Filename: "",
    Extension: "",
    Url: "",
    Uploader: "admin",
    Date: dateTimeString,
    Username: username,
    CaseNo: caseno,
    Size: "",
  });
  const { isUploadFileActive, setisUploadFileActive, fetchFiles } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set the Date field to the current timestamp
    setFormData((prevFormData) => ({
      ...prevFormData,
      Date: dateTimeString,
    }));

    const url = `${process.env.REACT_APP_API_URL}fileupload.php`;
    let encodedParams = "";

    // Iterate over formData object and encode key-value pairs
    for (const [key, value] of Object.entries(formData)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      encodedParams += `${encodedKey}=${encodedValue}&`;
    }

    // Remove the trailing "&" character
    encodedParams = encodedParams.slice(0, -1);

    axios
      .get(`${url}?${encodedParams}`)
      .then((response) => {
        // Optionally, you can reset the form data after successful submission
        setFormData({
          Filename: "",
          Extension: "",
          Url: "",
          Uploader: "admin",
          Date: dateTimeString, // Update the Date field after successful submission
          Username: username,
          CaseNo: caseno,
          Size: "",
        });
        window.alert("File Record Uploaded Successfully");
        fetchFiles();
        setisUploadFileActive(!isUploadFileActive);
      })
      .catch((error) => {
        window.alert("File Record Upload Failed, Please Try Again Later!");
      });
  };

  return (
    <div id="CreateCase">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <span
          onClick={() => setisUploadFileActive(!isUploadFileActive)}
          id="crossbtn"
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        <div>
          <label>Filename:</label>
          <input
            type="text"
            name="Filename"
            value={formData.Filename}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Extension:</label>
          <input
            type="text"
            name="Extension"
            value={formData.Extension}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>URL:</label>
          <input
            type="text"
            name="Url"
            value={formData.Url}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Uploader:</label>
          <input
            type="text"
            name="Uploader"
            value={formData.Uploader}
            readOnly
          />
        </div>
        <div>
          <label>Date:</label>
          <input type="text" name="Date" value={formData.Date} readOnly />
        </div>
        <div>
          <label>Username:</label>
          <input
            type="text"
            name="Username"
            value={formData.Username}
            readOnly
          />
        </div>
        <div>
          <label>Case Number:</label>
          <input type="text" name="CaseNo" value={formData.CaseNo} readOnly />
        </div>
        <div>
          <label>Size:</label>
          <input
            type="text"
            name="Size"
            value={formData.Size}
            onChange={handleChange}
          />
        </div>
        <input type="submit" value={"Submit"} />
      </form>
    </div>
  );
}

export default FileRecordUpload;
