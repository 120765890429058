import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function CasesList() {
  const [caseList, setcaseList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("spanUserToken") || "";
        const response = await axios.get(
          `${apiUrl}userdatafetch.php?token=${encodeURIComponent(token)}`
        );
        // Call fetchCases here to ensure it's executed after setting the username
        fetchCases(response.data.data.username);
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  const fetchCases = async (username) => {
    try {
      const response = await axios.get(
        `${apiUrl}casefetchusername.php`,
        {
          params: {
            ClientName: encodeURIComponent(decodeURIComponent(username)),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          maxContentLength: Infinity,
        }
      );
      setcaseList(response.data);
    } catch (error) {
      setcaseList([]);
    }
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const onecase = (ocase, index) => {
    return (
      <Link
        to={`${currentPath}/${ocase.CaseNo}`}
        className="notification"
        key={index}
      >
        <div className="notiglow"></div>
        <div className="notiborderglow"></div>
        <div className="notititle">{ocase.CaseTitle}</div>
        <div className="notibody">
          <strong> Case No :</strong> {ocase.CaseNo}
        </div>
        <div className="notibody">
          <strong> Case Manager :</strong> {ocase.LawyerName}
        </div>
        <div className="notibody">
          <strong> Status :</strong> {ocase.CurrentStatus}
        </div>
      </Link>
    );
  };
  return (
    <div id="CasesList">
      {caseList.map((oncase, index) => {
        return onecase(oncase, index);
      })}
      {caseList.length === 0 ? (
        <div className="nocasesview">
          <i className="fa-solid fa-ribbon"></i>
          <h1>There are no cases to Display!</h1>{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CasesList;
