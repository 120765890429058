import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import CreateCase from "./CreateCase";

function AdminCaseList() {
  const [caseList, setcaseList] = useState([]);
  const loc = useLocation();
  const [isCreateCaseActive, setisCreateCaseActive] = useState(false);
  const { username } = useParams();
  const url = `${process.env.REACT_APP_API_URL}casefetchusername.php`;
  const fetchCases = async () => {
    try {
      const response = await axios.get(
        url,
        {
          params: {
            ClientName: encodeURIComponent(decodeURIComponent(username)),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          maxContentLength: Infinity,
        }
      );
      setcaseList(response.data);
    } catch (error) {
      setcaseList([]);
    }
  };
  useEffect(() => {
    fetchCases();
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;
  const onecase = (ocase, index) => {
    return (
      <Link
        to={`${currentPath}/${ocase.CaseNo}`}
        className="notification"
        key={index}
      >
        <div className="notiglow"></div>
        <div className="notiborderglow"></div>
        <div className="notititle">{ocase.CaseTitle}</div>
        <div className="notibody">
          <strong> Case No :</strong> {ocase.CaseNo}
        </div>
        <div className="notibody">
          <strong> Case Manager :</strong> {ocase.LawyerName}
        </div>
        <div className="notibody">
          <strong> Status :</strong> {ocase.CurrentStatus}
        </div>
      </Link>
    );
  };
  return (
    <>
      {isCreateCaseActive ? (
        <CreateCase
          setisCreateCaseActive={setisCreateCaseActive}
          isCreateCaseActive={isCreateCaseActive}
          fetchCases={fetchCases}
        />
      ) : (
        <></>
      )}
      <div id="CasesList">
        <div
          className="filesoptions"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>
            Select Case to view
            {loc.pathname.includes("/status") ? " Status" : " Files related"}
          </h2>

          <button
            style={{ backgroundColor: "#58e04c" }}
            onClick={() => setisCreateCaseActive(!isCreateCaseActive)}
          >
            {" "}
            <i className="fa-solid fa-plus"></i> Create Case
          </button>
        </div>
        {caseList.map((oncase, index) => {
          return onecase(oncase, index);
        })}
        {caseList.length === 0 ? (
          <div className="nocasesview">
            <i className="fa-solid fa-ribbon"></i>
            <h1>There are no cases to Display!</h1>{" "}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default AdminCaseList;
