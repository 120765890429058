import React, { useEffect, useState, useCallback } from "react";
import logo from "./Images/logo .png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NavbarTraningMobile from "./NavbarTraningMobile";
function MobileNavbar() {
  const [isBurgerActive, setisBurgerActive] = useState(false);
  const [isLevel2Active, setisLevel2Active] = useState(false);
  const [dropdownContent, setDropdownContent] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [user, setUser] = useState("Login");
  const token = localStorage.getItem("spanUserToken");
  const [isSearchBtnActive, setisSearchBtnActive] = useState(false);

  const [query, setquery] = useState("");
  const his = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    his(`/search/${query}`);
  };
  const fetchData = useCallback(async () => {
    if (token && token !== "") {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }userdatafetch.php?token=${encodeURIComponent(token)}`
        );
        setUser(response.data.data.username);
      } catch (error) {}
    }
  }, [token]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    fetchData();
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAccordionClick = (event) => {
    const accordionButton = event.target;
    const content = accordionButton.nextElementSibling;

    // Close all accordions
    const allAccordionContents =
      document.querySelectorAll(".accordion-content");
    allAccordionContents.forEach((accordionContent) => {
      if (accordionContent !== content) {
        accordionContent.style.maxHeight = null;
        accordionContent.previousElementSibling.classList.remove("is-open");
      }
    });

    // Toggle the clicked accordion
    accordionButton.classList.toggle("is-open");

    if (content.style.maxHeight) {
      content.style.maxHeight = null; // Close the accordion
    } else {
      content.style.maxHeight = content.scrollHeight + "px"; // Open the accordion
    }
  };

  const ServicesDropdown = () => {
    return (
      <>
        <span className="accordion" onClick={handleAccordionClick}>
          Legal Brand Protection
        </span>
        <div className="accordion-content">
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/overview"
          >
            Overview
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/ipregistration"
          >
            IP Registration and Prosecution
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/ipaudits"
          >
            IP Audits, SC Audits, EM Audit, Security Audits
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/intellectualproperties"
          >
            Intellectual Property
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/confidentialinformation"
          >
            Confidential Information and Trade Secrets
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/healthcareandregulations"
          >
            Healthcare and Regulatory Laws
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/mediaentertainmentandsportslaws"
          >
            Media, Entertainment & Sports Laws
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/leagalbrandprotection/digitalanditlaw"
          >
            Digital & IT Laws
          </Link>
        </div>
        <span className="accordion" onClick={handleAccordionClick}>
          Strategic Consulting
        </span>
        <div className="accordion-content">
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/stratagicconsulting/overview"
          >
            Overview
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/stratagicconsulting/comprehensivelegalandbusinessriskconsultancy"
          >
            Comprehensive Legal and Business Risk Consulting
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/stratagicconsulting/riskassesmentandmigrationplan"
          >
            Risk Assessments and Mitigation Plans
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/stratagicconsulting/customizedconsultingservice"
          >
            Customized Consulting Services
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/stratagicconsulting/chainsupplysecurityandintegratityprogram"
          >
            Supply Chain Security/Integrity Programs
          </Link>
        </div>

        <span className="accordion" onClick={handleAccordionClick}>
          Investigations & Due Diligence
        </span>
        <div className="accordion-content">
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/counterfeit"
          >
            Counterfeits
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/diversion"
          >
            Diversion
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/producttampering"
          >
            Product Tampering
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/leadgeneration"
          >
            Lead Generation
          </Link>
          {/* <li>Due Diligence</li> */}
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/counterpartyduediligence"
          >
            Counter Party Due Diligence
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/seniormanagementduediligence"
          >
            Senior Management Due Diligence
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/integrityduediligence"
          >
            Integrity Due Diligence
          </Link>
          {/* <li>Theft Investigations </li> */}
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/facility"
          >
            Facility
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/intransit"
          >
            In-Transit
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/digital"
          >
            Digital
          </Link>
          {/* <li>KYC and Background Checks </li> */}
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/aml"
          >
            AML
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/kyc"
          >
            KYC
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/marketresearch"
          >
            Market Research (Primary and Secondary)
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/marketintelligence"
          >
            Market Intelligence Gathering
          </Link>
          {/* <li>Forensic Due Diligence</li> */}
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/cyberforensics"
          >
            Cyber Forensics
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/informationsecurityandsystem"
          >
            Information Security and Systems
          </Link>
          {/* <li>Supply Chain Investigations </li> */}

          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/investigationandduedilligence/sourceinvestigation"
          >
            Source Investigations
          </Link>
        </div>

        <span className="accordion" onClick={handleAccordionClick}>
          {/* Training
           */}
          Leadership & Development
        </span>
        <NavbarTraningMobile
          setisBurgerActive={setisBurgerActive}
          setisLevel2Active={setisLevel2Active}
        />

        <span className="accordion" onClick={handleAccordionClick}>
          Services for the Legal Industry
        </span>
        <div className="accordion-content">
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);

              setisLevel2Active(false);
            }}
            to="/services/serviceforlegalindustries/tminuse"
          >
            TM In-Use
          </Link>
        </div>
      </>
    );
  };

  //Dropdown Contents

  const SpanSuitDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/spansuit/systemprocessandtools"
        >
          Systems, Processes and Tools
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/spansuit/mobileappview"
        >
          Mobile APP
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/spansuit/brandprotection"
        >
          Brand Protection
        </Link>
      </>
    );
  };
  const ContactUsDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/contactus"
        >
          Contact Form
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/inquiry"
        >
          Inquiry/Consultation Request
        </Link>
      </>
    );
  };
  const IndustriesServedDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/industries/overviewofindustries"
        >
          Overview of Industries
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/industries/successtory"
        >
          Success Stories / Case Studies / Testimonials
        </Link>
      </>
    );
  };
  const ResourcesToolkitsDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/resources/blogs"
        >
          Blog / Articles / Videos
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/resources/whitepaper"
        >
          Whitepapers
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/resources/casestudy"
        >
          Case Studies
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/resources/brandprotection"
        >
          Brand Protection Toolkits
        </Link>
      </>
    );
  };
  const AboutUsDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/history"
        >
          History
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/about/directorsmessage"
        >
          Director's Message
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/missionvisionvalues"
        >
          Mission, Vision and Values
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/valuesandexpectations"
        >
          Value Creation
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/fundingmodels"
        >
          Funding Model
        </Link>

        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/strategy"
        >
          Strategy
        </Link>

        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/capability"
        >
          Capability
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/bestpractices"
        >
          Best Practices
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/leadershipteam"
        >
          Leadership Team
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/organisationstructure"
        >
          Organisation Structure
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/rolesandresponsibility"
        >
          Roles and Responsibilities
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/governance"
        >
          Governance
        </Link>
        {/* <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/partnerships"
        >
          
          Partnerships
        </Link> */}
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/careers"
        >
          Careers
        </Link>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/aboutus/offices"
        >
          Offices
        </Link>
      </>
    );
  };

  const PartnershipDropdown = () => {
    return (
      <>
        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
          to="/partnersandrepresentativeclients/partnership/partners"
        >
          Partners
        </Link>
        <span className="accordion" onClick={handleAccordionClick}>
          Our Representative Clients
        </span>
        <div className="accordion-content">
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/pharmacompanies"
          >
            Pharma Companies
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/fmcgcompanies"
          >
            FMCG Companies
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/hardwareandadhesive"
          >
            Hardware & Adhesives
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/automobiles"
          >
            Automobiles
          </Link>

          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/electronics"
          >
            Electronics/Mobiles
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/agrichemical"
          >
            Agri-Chemicals
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/fashion"
          >
            Fashio/Shoes/Apparel
          </Link>
          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/alcohol"
          >
            Alcohol/Beverages
          </Link>

          <Link
            onClick={() => {
              setisBurgerActive(!isBurgerActive);
              setisLevel2Active(false);
            }}
            to="/partnersandrepresentativeclients/representativeclients/consortiums"
          >
            Consortiums
          </Link>
        </div>
      </>
    );
  };

  // Level 1 Handing Function

  const handleNavHover = (index) => {
    // Set dropdown content based on the hovered index
    switch (index) {
      case 1:
        setDropdownContent(<SpanSuitDropdown />);
        setisLevel2Active(true);
        break;
      case 2:
        setDropdownContent(<ServicesDropdown />);
        setisLevel2Active(true);

        break;
      case 3:
        setDropdownContent(<IndustriesServedDropdown />);
        setisLevel2Active(true);

        break;
      case 4:
        setDropdownContent(<ResourcesToolkitsDropdown />);
        setisLevel2Active(true);

        break;
      case 5:
        setDropdownContent(<AboutUsDropdown />);
        setisLevel2Active(true);
        break;
      case 6:
        setDropdownContent(<ContactUsDropdown />);
        setisLevel2Active(true);
        break;
      case 7:
        setDropdownContent(<PartnershipDropdown />);
        setisLevel2Active(true);

        break;
      default:
        setDropdownContent(null);
    }
  };

  return (
    <div id="MobileNavbar">
      <div
        id="mobNav"
        style={
          isScrolled
            ? { backgroundColor: "white" }
            : { backgroundColor: "#fff8e3" }
        }
      >
        <Link clasName="level1navLink" to="/">
          <img src={logo} alt="" />
        </Link>
        {isSearchBtnActive ? (
          <form
            className="searchnavbar-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <label htmlFor="search">
              <input
                className="searchnavbar-input"
                type="text"
                required=""
                placeholder="Search"
                id="search"
                value={query}
                onChange={(e) => {
                  setquery(e.target.value);
                }}
              />
              <div className="searchnavbar-fancy-bg" />
              <div className="searchnavbar-search">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="searchnavbar-icon"
                >
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                  </g>
                </svg>
              </div>
              <button
                className="searchnavbar-close-btn"
                type="reset"
                onClick={() => {
                  setisSearchBtnActive(!isSearchBtnActive);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="searchnavbar-close-icon"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </label>
          </form>
        ) : (
          <i
            onClick={() => {
              setisSearchBtnActive(!isSearchBtnActive);
            }}
            className="fa-solid fa-magnifying-glass"
          ></i>
        )}
        <div
          className="burger"
          onClick={() => {
            setisBurgerActive(!isBurgerActive);

            setisLevel2Active(false);
          }}
        >
          <div
            className="top"
            style={
              isBurgerActive
                ? { transform: "rotate(45deg) translateY(8px)" }
                : { transform: "rotate(0deg) translateY(0%)" }
            }
          ></div>
          <div
            className="middle"
            style={
              isBurgerActive
                ? { transform: "translateX(500%)" }
                : { transform: "translateX(0%)" }
            }
          ></div>
          <div
            className="bottom"
            style={
              isBurgerActive
                ? { transform: "rotate(-45deg) translateY(-8px)" }
                : { transform: "rotate(0deg) translateY(0%)" }
            }
          ></div>
        </div>
      </div>
      <div
        id="mobNavL1"
        style={isBurgerActive ? { right: "0%" } : { right: "-101%" }}
      >
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(1);
          }}
        >
          SPAN Suite
        </span>
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(2);
          }}
        >
          Services
        </span>
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(7);
          }}
        >
          Partners
        </span>
        {/* <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);
          }}
          to="/partnership"
        >
          Partners
        </Link> */}
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(3);
          }}
        >
          Industries
        </span>
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(4);
          }}
        >
          Resources and Toolkits
        </span>
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(5);
          }}
        >
          About Us
        </span>
        <span
          onClick={() => {
            setisLevel2Active(true);
            handleNavHover(6);
          }}
        >
          Contact Us
        </span>

        <Link
          onClick={() => {
            setisBurgerActive(!isBurgerActive);
          }}
          to="/login"
          className="my-account"
        >
          <i className="fa-solid fa-user"></i>
          {user}
        </Link>
      </div>
      <div
        id="mobNavL2"
        style={isLevel2Active ? { right: "0%" } : { right: "-101%" }}
      >
        <div
          id="back-btn"
          onClick={() => {
            setisLevel2Active(false);
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
          Back
        </div>

        {dropdownContent}
      </div>
    </div>
  );
}

export default MobileNavbar;
