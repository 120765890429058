import React, { useState } from "react";

function EditStatus(props) {
  const {
    isEditStepsActive,
    setisEditStepsActive,
    status,
    setStatus,
    editStatus,
  } = props;

  const [tempStatus, setTempStatus] = useState(status);

  const handleChange = (index, field, value) => {
    const updatedTempStatus = [...tempStatus];
    updatedTempStatus[index] = {
      ...updatedTempStatus[index],
      [field]: value,
    };

    // Ensure that there's always a -1 before 1
    if (field === "status_step_value") {
      for (let i = 0; i < updatedTempStatus.length; i++) {
        if (i < index) {
          updatedTempStatus[i] = {
            ...updatedTempStatus[i],
            [field]: -1, // Make sure it's checked
          };
        } else if (i > index) {
          updatedTempStatus[i] = {
            ...updatedTempStatus[i],
            [field]: 0, // Make sure it's unchecked
          };
        }
      }
    }

    setTempStatus(updatedTempStatus); // Update temporary status array
  };

  const handleSubmit = async () => {
    try {
      const success = await editStatus(tempStatus);
      if (success) {
        setStatus(tempStatus);
        setisEditStepsActive(false);
      } else {
        window.alert("Error updating status in database. Please try again.");
      }
    } catch (error) {
      window.alert("An error occurred. Please try again.");
    }
  };

  return (
    <div id="EditStatus">
      <div className="allstatusoptions">
        <span
          onClick={() => setisEditStepsActive(!isEditStepsActive)}
          id="crossbtn"
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        {tempStatus.map((onestep, index) => (
          <div key={index}>
            <input
              type="text"
              value={onestep.status_step_title}
              onChange={(e) =>
                handleChange(index, "status_step_title", e.target.value)
              }
            />
            <label className="check-container">
              <input
                type="checkbox"
                checked={onestep.status_step_value === -1}
                onChange={(e) =>
                  handleChange(
                    index,
                    "status_step_value",
                    e.target.checked ? -1 : 0
                  )
                }
              />
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  pathLength="575.0541381835938"
                  className="path"
                ></path>
              </svg>
            </label>
            <input
              type="text"
              value={onestep.step_remark}
              onChange={(e) =>
                handleChange(index, "step_remark", e.target.value)
              }
            />
          </div>
        ))}
        <input type="submit" value="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default EditStatus;
