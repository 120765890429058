import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader";

function AdminContactUs() {
  const [conversation, setConversation] = useState([]);
  const [issending, setissending] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [email, setEmail] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const { username } = useParams();

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}receivemessage.php?Username=${username}`
      );
      if (response.data.status === "success") {
        const validMessages = response.data.data.filter(
          (message) => !isNaN(new Date(message.TimeStamp).getTime())
        );

        validMessages.sort((a, b) => {
          return new Date(a.TimeStamp) - new Date(b.TimeStamp);
        });

        setConversation(validMessages);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchMessages();
      const intervalId = setInterval(fetchMessages, 15000); // Fetch messages every 15 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
    setEmail(localStorage.getItem("SpanUserEmail"));
  }, [username]);

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      setissending(true);
      try {
        const timeStamp = new Date().toISOString();
        const response = await axios.get(
          `${apiUrl}sendmessage.php?TimeStamp=${encodeURIComponent(
            timeStamp
          )}&Message=${encodeURIComponent(
            newMessage
          )}&Sender=admin&Username=${encodeURIComponent(username)}`
        );
        if (response.data.status === "success") {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              TimeStamp: timeStamp,
              Message: newMessage,
              Sender: "admin",
              Username: username,
            },
          ]);
          setNewMessage("");
          await sendEmailToUser(newMessage);
        }
      } catch (error) {
        console.error("Failed to send message:", error);
      } finally {
        setissending(false);
      }
    }
  };

  const sendEmailToUser = async (message) => {
    try {
      const subject = "New Message from Admin";
      const body = `
        <h1>You have a new message from the admin</h1>
        <p><strong>Message:</strong> ${message}</p>
        <p>Please <a href="https://spanip.com/user/contactus">Click Here</a> to see message </p>
      `;
      await axios.post(process.env.REACT_APP_MAIL_API, {
        receiverMail: email,
        subject,
        body,
      });
    } catch (error) {
      console.error("Failed to send email to user:", error);
    }
  };

  const oneMessage = (chat, index) => {
    const time = new Date(chat.TimeStamp).toLocaleTimeString();
    return (
      <div className={chat.Sender} key={index}>
        <h6>{chat.Sender}</h6>
        <p>{chat.Message}</p>
        <span>{time}</span>
      </div>
    );
  };

  return (
    <>
      {issending ? <Loader /> : null}
      <div className="UserContactUs">
        <div id="conversation">
          {conversation.map((chat, index) => oneMessage(chat, index))}
        </div>
        <div id="sendbox">
          <input
            type="text"
            placeholder="Send Message.."
            value={newMessage}
            onChange={handleMessageChange}
          />
          <i
            className="fa-solid fa-paper-plane"
            onClick={handleSendMessage}
          ></i>
        </div>
      </div>
    </>
  );
}

export default AdminContactUs;
