import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditStatus from "./EditStatus";
import axios from "axios";

function AdminStatusView() {
  const [isAddStepActive, setisAddStepActive] = useState(false);
  const [isDeleteStepActive, setisDeleteStepActive] = useState(false);
  const [isEditStepsActive, setisEditStepsActive] = useState(false);
  const [status, setStatus] = useState([]);
  const { caseno } = useParams();
  const fetchStatus = async () => {
    try {
      const responce = await axios.get(
        `${process.env.REACT_APP_API_URL}caseftch.php?CaseNo=${caseno}`
      );
      setStatus(JSON.parse(responce.data.data[0].Steps) || []);
    } catch (error) {}
  };
  useEffect(() => {
    fetchStatus();
  }, []);
  const editStatus = async (newStatus) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }statusupdate.php?caseNo=${caseno}&steps=${JSON.stringify(newStatus)}`
      );
      window.alert("Status Successfully Updated");
      return true; // Return true if update in database is successful
    } catch (error) {
      return false; // Return false if there's an error
    }
  };

  const AddStep = () => {
    const [formData, setFormData] = useState({
      status_step_title: "",
      status_step_value: 1,
      step_remark: "",
      step_number: status.length + 1,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newStatus = [...status, formData]; // Create a new array by adding formData
      const updatedInDatabase = await editStatus(newStatus); // Update status in the database
      if (updatedInDatabase) {
        setStatus(newStatus); // Update local state only if database update is successful
        setisAddStepActive(false);
      } else {
        window.alert("Error updating status in database. Please try again."); // Display error message if database update fails
      }
    };
    return (
      <div id="CreateCase">
        <form autoComplete="off" id="StepCreateForm" onSubmit={handleSubmit}>
          <span
            onClick={() => setisAddStepActive(!isAddStepActive)}
            id="crossbtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <div>
            <label>Step Title:</label>
            <input
              type="text"
              name="status_step_title"
              value={formData.status_step_title}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Step Remark:</label>
            <input
              type="text"
              name="step_remark"
              value={formData.step_remark}
              onChange={handleChange}
            />
          </div>
          <input type="submit" value={"Submit"} />
        </form>
      </div>
    );
  };
  const DeteleStep = () => {
    const [tempstatus, setTempstatus] = useState(status);

    const deleteStep = (index) => {
      const updatedTempStatus = tempstatus.filter((_, i) => i !== index);

      // Adjust step numbers after deletion
      const adjustedStatus = updatedTempStatus.map((step, idx) => ({
        ...step,
        step_number: idx + 1,
      }));

      setTempstatus(adjustedStatus);
    };

    const handleSubmit = async () => {
      // Update status in the database
      const updatedInDatabase = await editStatus(tempstatus);

      if (updatedInDatabase) {
        setStatus(tempstatus); // Update local state only if database update is successful
        setisDeleteStepActive(false);
      } else {
        window.alert("Error updating status in database. Please try again."); // Display error message if database update fails
      }
    };
    return (
      <div id="CreateCase">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <span
            onClick={() => setisDeleteStepActive(!isDeleteStepActive)}
            id="crossbtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          {tempstatus.map((step, index) => (
            <>
              <p>{step.status_step_title}</p>
              <button className="bin-button" onClick={() => deleteStep(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 39 7"
                  className="bin-top"
                >
                  <line strokeWidth={4} stroke="white" y2={5} x2={39} y1={5} />
                  <line
                    strokeWidth={3}
                    stroke="white"
                    y2="1.5"
                    x2="26.0357"
                    y1="1.5"
                    x1={12}
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 33 39"
                  className="bin-bottom"
                >
                  <mask fill="white" id="path-1-inside-1_8_19">
                    <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z" />
                  </mask>
                  <path
                    mask="url(#path-1-inside-1_8_19)"
                    fill="white"
                    d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                  />
                  <path strokeWidth={4} stroke="white" d="M12 6L12 29" />
                  <path strokeWidth={4} stroke="white" d="M21 6V29" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 89 80"
                  className="garbage"
                >
                  <path
                    fill="white"
                    d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                  />
                </svg>
              </button>
            </>
          ))}
          <input type="submit" value={"Submit"} />
        </form>
      </div>
    );
  };

  const oneStep = (step, index) => {
    return (
      <>
        <div class="tooltip-container" key={index}>
          <p>{step.status_step_title}</p>
          <span class="tooltip">
            <h5>{step.status_step_title}</h5>
            <p>{step.step_remark}</p>
          </span>
          <span class="text"></span>
        </div>
        {index === status.length - 1 ? (
          <></>
        ) : (
          <div
            style={{
              backgroundColor:
                step.status_step_value === -1 ? "#00f208" : "grey",
            }}
            className="line"
          ></div>
        )}
      </>
    );
  };
  return (
    <>
      {isAddStepActive ? <AddStep /> : <></>}
      {isDeleteStepActive ? <DeteleStep /> : <></>}
      {isEditStepsActive ? (
        <EditStatus
          isEditStepsActive={isEditStepsActive}
          setisEditStepsActive={setisEditStepsActive}
          status={status}
          setStatus={setStatus}
          editStatus={editStatus}
        />
      ) : (
        <></>
      )}
      <div id="UserStatus">
        <div className="filesoptions">
          <Link to="/admin/" className="backoption">
            <i className="fa-solid fa-angle-left"></i>
            <h1>Case no : {caseno}</h1>
          </Link>
          <button
            style={{ backgroundColor: "#dde04c" }}
            onClick={() => setisEditStepsActive(true)}
          >
            <i className="fa-solid fa-pen-to-square"></i> Edit Steps
          </button>
          <button
            style={{ backgroundColor: "#58e04c" }}
            onClick={() => setisAddStepActive(!isAddStepActive)}
          >
            <i className="fa-solid fa-plus"></i> Add steps
          </button>
          <button
            style={{ backgroundColor: "#da0e29" }}
            onClick={() => setisDeleteStepActive(!isDeleteStepActive)}
          >
            <i className="fa-solid fa-plus"></i> Delete steps
          </button>
        </div>
        <div className="statusdiv">
          {status.map((onestep, index) => {
            return oneStep(onestep, index);
          })}
        </div>
      </div>
    </>
  );
}

export default AdminStatusView;
