import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function UserList() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [myAccount, setMyAccount] = useState({});
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}usersearch.php`;

  const fetchAdmin = async () => {
    try {
      const token = localStorage.getItem("spanAdminToken");
      if (!token) {
        navigate("/login/admin");
        return;
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}adminfetch.php?token=${token}`
      );
      setMyAccount(res.data.data);
    } catch (error) {
      navigate("/login/admin");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const handleSearch = async (query = searchQuery) => {
    try {
      const response = await axios.get(`${url}?search=${query}`);
      setUsers(response.data.data);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(users.length / usersPerPage)) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(users.length / usersPerPage);
  const pageRangeStart = indexOfFirstUser + 1;
  const pageRangeEnd = Math.min(indexOfLastUser, users.length);

  return (
    <div id="AdminAllUser">
      <div id="adminsearchbox">
        <h2>Search Users</h2>
        <div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={() => handleSearch(searchQuery)}>Search</button>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            User {pageRangeStart}-{pageRangeEnd} of {users.length}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      <div className="filesoptions">
        {myAccount.Username === "admin" ? (
          <>
            <Link to={"/admin/createuser"}>
              <button style={{ backgroundColor: "#58e04c" }}>
                <i className="fa-solid fa-plus"></i> Create User
              </button>
            </Link>
            <button
              style={{ marginLeft: "10px", backgroundColor: "#58e04c" }}
              onClick={() => handleSearch("")}
            >
              Show All Users
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
      <div id="UserList">
        {searchQuery && currentUsers.length === 0 ? (
          <div className="no-results">
            No users found matching your search criteria.
          </div>
        ) : (
          currentUsers.map((user) => (
            <Link
              to={`/admin/user/${user.username}`}
              key={user.id}
              className="admin-user-card"
            >
              <img
                src={
                  user.profile_photo
                    ? user.profile_photo
                    : process.env.PUBLIC_URL +
                      "/assets/images/profil_photo.webp"
                }
                alt=""
                className="admin-user-card-image"
              />
              <div className="admin-user-info">
                <div>
                  <span className="admin-user-label">Name:</span>{" "}
                  {decodeURIComponent(user.name)}
                </div>
                <div>
                  <span className="admin-user-label">Username:</span>{" "}
                  {decodeURIComponent(user.username)}
                </div>
                <div>
                  <span className="admin-user-label">Email:</span>{" "}
                  {decodeURIComponent(user.email)}
                </div>
                <div>
                  <span className="admin-user-label">Phone No:</span>{" "}
                  {decodeURIComponent(user.phone_no)}
                </div>
                <div>
                  <span className="admin-user-label">Role:</span>{" "}
                  {decodeURIComponent(user.role)}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}

export default UserList;
