import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Components/Loader";

function SignIn() {
  const [isPassword, setIsPassword] = useState(true);
  const navigate = useNavigate();
  const [issending, setissending] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const [userInfo, setUserInfo] = useState({
    username: "",
    fname: "",
    lname: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    email: "",
    password: "",
    phoneno: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const validateUsername = (username) => {
    return /^[a-zA-Z0-9\s]+$/.test(username);
  };

  const validateName = (name) => {
    return /^[a-zA-Z\s]+$/.test(name);
  };

  const validateNumeric = (value) => {
    return /^[0-9+\s]+$/.test(value);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,16}$/.test(
      password
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !userInfo.username ||
      !userInfo.fname ||
      !userInfo.lname ||
      !userInfo.pincode ||
      !userInfo.city ||
      !userInfo.state ||
      !userInfo.country ||
      !userInfo.email ||
      !userInfo.password ||
      !userInfo.phoneno
    ) {
      window.alert("Please fill all the fields");
      return;
    }

    if (!validateUsername(userInfo.username)) {
      window.alert("Username should contain only alphanumeric characters.");
      return;
    }

    if (!validateName(userInfo.fname)) {
      window.alert("First name should contain only alphabetical characters.");
      return;
    }

    if (!validateName(userInfo.lname)) {
      window.alert("Last name should contain only alphabetical characters.");
      return;
    }

    if (!validateNumeric(userInfo.pincode)) {
      window.alert("Pincode should contain only numeric characters.");
      return;
    }

    if (!validateName(userInfo.city)) {
      window.alert("City should contain only alphabetical characters.");
      return;
    }

    if (!validateName(userInfo.state)) {
      window.alert("State should contain only alphabetical characters.");
      return;
    }

    if (!validateName(userInfo.country)) {
      window.alert("Country should contain only alphabetical characters.");
      return;
    }

    if (!validateEmail(userInfo.email)) {
      window.alert("Invalid email format.");
      return;
    }

    if (!validatePassword(userInfo.password)) {
      window.alert(
        "Password should be 8-16 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    if (!validateNumeric(userInfo.phoneno)) {
      window.alert(
        "Phone number should contain only numeric characters and '+' sign."
      );
      return;
    }

    const currentDate = new Date().toISOString().split("T")[0];
    const address = `${userInfo.pincode}, ${userInfo.city}, ${userInfo.state}, ${userInfo.country}`;
    const url = `${apiUrl}userregistration.php`;

    try {
      setissending(true);
      await axios.get(
        url,
        {
          params: {
            name: encodeURIComponent(userInfo.fname + " " + userInfo.lname),
            address: encodeURIComponent(address),
            phone_no: encodeURIComponent(userInfo.phoneno),
            email: encodeURIComponent(userInfo.email),
            password: encodeURIComponent(userInfo.password),
            login_date: encodeURIComponent(currentDate),
            username: encodeURIComponent(userInfo.username),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          maxContentLength: Infinity,
        }
      );

      await sendEmailToAdmin(userInfo);
      setissending(false);
      navigate("/admin");
    } catch (error) {
      setissending(false);
      window.alert("Registration failed. Please try again.");
      console.error("An error occurred:", error);
    }
  };

  const sendEmailToAdmin = async (userDetails) => {
    try {
      const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
      const subject = "New User Registration";
      const body = `
        <h1>New User Registered</h1>
        <p><strong>Username:</strong> ${userDetails.username}</p>
        <p><strong>First Name:</strong> ${userDetails.fname}</p>
        <p><strong>Last Name:</strong> ${userDetails.lname}</p>
        <p><strong>Pincode:</strong> ${userDetails.pincode}</p>
        <p><strong>City:</strong> ${userDetails.city}</p>
        <p><strong>State:</strong> ${userDetails.state}</p>
        <p><strong>Country:</strong> ${userDetails.country}</p>
        <p><strong>Email:</strong> ${userDetails.email}</p>
        <p><strong>Phone Number:</strong> ${userDetails.phoneno}</p>
        <p><strong>Password:</strong> ${userDetails.password}</p>
      `;
      await axios.post(process.env.REACT_APP_MAIL_API, {
        receiverMail: adminEmail,
        subject,
        body,
      });
    } catch (error) {
      console.error("Failed to send email to admin:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("spanAdminToken");
    const fetchAdmin = async () => {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URL}adminfetch.php?token=${token}`
        );
      } catch (error) {
        navigate("/login/admin");
      }
    };
    fetchAdmin();
  }, [navigate]);

  return (
    <>
      {issending ? <Loader /> : null}
      <form className="SignIn" autoComplete="off">
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={userInfo.username}
            onChange={handleChange}
          />
          <label htmlFor="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="fname"
            value={userInfo.fname}
            onChange={handleChange}
          />
          <label htmlFor="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            name="lname"
            value={userInfo.lname}
            onChange={handleChange}
          />
          <div className="addresscontainer">
            <span>
              <label htmlFor="pincode">Pincode</label>
              <input
                type="number"
                id="pincode"
                name="pincode"
                value={userInfo.pincode}
                onChange={handleChange}
              />
            </span>
            <span>
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={userInfo.city}
                onChange={handleChange}
              />
            </span>
            <span>
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={userInfo.state}
                onChange={handleChange}
              />
            </span>
            <span>
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={userInfo.country}
                onChange={handleChange}
              />
            </span>
          </div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={userInfo.email}
            onChange={handleChange}
          />
          <div className="passwordcontainer">
            <label htmlFor="password">Password</label>
            <input
              type={isPassword ? "password" : "text"}
              id="password"
              name="password"
              value={userInfo.password}
              onChange={handleChange}
            />
            {isPassword ? (
              <i
                className="fa-regular fa-eye"
                onClick={() => setIsPassword(!isPassword)}
              ></i>
            ) : (
              <i
                className="fa-regular fa-eye-slash"
                onClick={() => setIsPassword(!isPassword)}
              ></i>
            )}
          </div>
          <label htmlFor="phoneno">Phone Number</label>
          <input
            type="text"
            id="phoneno"
            name="phoneno"
            value={userInfo.phoneno}
            onChange={handleChange}
          />
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default SignIn;
