import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function Status() {
  const [staus, setstaus] = useState([]);

  const { caseno } = useParams();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const responce = await axios.get(
          `${process.env.REACT_APP_API_URL}caseftch.php?CaseNo=${caseno}`
        );
        setstaus(JSON.parse(responce.data.data[0].Steps) || []);
      } catch (error) {}
    };
    fetchStatus();
  }, []);
  const oneStep = (step, index) => {
    return (
      <>
        <div class="tooltip-container" key={index}>
          <p>{step.status_step_title}</p>
          <span class="tooltip">
            <h5>{step.status_step_title}</h5>
            <p>{step.step_remark}</p>
          </span>
          <span class="text"></span>
        </div>
        {index === staus.length - 1 ? (
          <></>
        ) : (
          <div
            style={{
              backgroundColor:
                step.status_step_value === -1 ? "#00f208" : "grey",
            }}
            className="line"
          ></div>
        )}
      </>
    );
  };
  return (
    <div id="UserStatus">
      <Link to="/user/status" className="backoption">
        <i className="fa-solid fa-angle-left"></i>
        <h1>Case no : {caseno}</h1>
      </Link>
      <div className="statusdiv">
        {staus.map((onestep, index) => {
          return oneStep(onestep, index);
        })}
      </div>
    </div>
  );
}

export default Status;
