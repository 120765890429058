import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function CheckMyfiles() {
  const [filelist, setfilelist] = useState([]);
  const { caseno } = useParams();

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}filefetch.php?CaseNo=${caseno}`
        );
        setfilelist(response.data.data);
      } catch (error) {}
    };
    fetchFiles();
  }, [caseno]);

  const oneRow = (file, index) => {
    return (
      <li key={index}>
        <div className="srdiv">{index + 1}</div>
        <div>{file.Filename}</div>
        <div>{file.TimeStamp}</div>
        <div>{file.Uploader}</div>
        <div>{file.Size}</div>
        <a href={file.Url} download>
          <i className="fa-solid fa-download"> </i>
        </a>
      </li>
    );
  };

  return (
    <div id="UserCheckMyfiles">
      <div className="filesoptions">
        <Link to="/user/checkmyfiles" className="backoption">
          <i className="fa-solid fa-angle-left"></i>
          <h1>Case no : {caseno}</h1>
        </Link>
      </div>
      <ul>
        <li className="headli">
          <div className="srdiv">Sr.no</div>
          <div className="srdiv">Name</div>
          <div className="srdiv">Upload Date</div>
          <div className="srdiv">Uploaded By</div>
          <div className="srdiv">Size</div>
          <div></div>
        </li>
        {filelist.map((file, index) => oneRow(file, index))}
      </ul>
    </div>
  );
}

export default CheckMyfiles;
