import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function Settings() {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    organization: "",
    phone: "",
    pinCode: "",
    city: "",
    state: "",
    username: "",
    country: "",
  });

  const { username } = useParams();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("spanUserToken") || "";
        const response = await axios.get(
          `${apiUrl}userdatafetch.php?token=${encodeURIComponent(token)}`
        );
        // Call fetchCases here to ensure it's executed after setting the username
        const userDataFromApi = response.data.data;

        // Decode URI-encoded data and update state variables
        const adressString = decodeURIComponent(userDataFromApi.address);

        setUserData({
          name: decodeURIComponent(userDataFromApi.name),
          email: decodeURIComponent(userDataFromApi.email),
          username: decodeURIComponent(userDataFromApi.username),
          organization: decodeURIComponent(userDataFromApi.company_name),
          phone: decodeURIComponent(userDataFromApi.phone_no),
          pinCode: decodeURIComponent(adressString.split(",")[0]),
          city: decodeURIComponent(adressString.split(",")[1]),
          state: decodeURIComponent(adressString.split(",")[2]),
          country: decodeURIComponent(adressString.split(",")[3]),
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }usersearch.php?search=${encodeURIComponent(username)}`
        );

        if (response.data.status === "success") {
          const usersDataFromApi = response.data.data;

          // Find the user with the exact username match
          const userDataFromApi = usersDataFromApi.find(
            (user) => user.username === username
          );

          if (userDataFromApi) {
            // Decode URI-encoded data and update state variables
            const addressString = decodeURIComponent(userDataFromApi.address);

            setUserData({
              name: decodeURIComponent(userDataFromApi.name),
              email: decodeURIComponent(userDataFromApi.email),
              username: decodeURIComponent(userDataFromApi.username),
              organization: decodeURIComponent(userDataFromApi.company_name),
              phone: decodeURIComponent(userDataFromApi.phone_no),
              pinCode: decodeURIComponent(addressString.split(",")[0]),
              city: decodeURIComponent(addressString.split(",")[1]),
              state: decodeURIComponent(addressString.split(",")[2]),
              country: decodeURIComponent(addressString.split(",")[3]),
            });
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, [username]);

  // Function to handle updates for each input field
  const handleUpdate = (key, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [key]: value,
    }));
  };

  return (
    <div id="UserSettings">
      <div className="form">
        <div>
          <label htmlFor="">Name</label>
          <input
            type="text"
            value={userData.name}
            onChange={(e) => handleUpdate("name", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updatename.php?username=${userData.username}&name=${userData.name}`
                );
                window.alert("Succesfully updated Name");
              } catch (error) {
                window.alert("Error in  updating Name");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">Email</label>
          <input
            type="email"
            value={userData.email}
            onChange={(e) => handleUpdate("email", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updateemail.php?username=${userData.username}&email=${userData.email}`
                );
                window.alert("Succesfully updated Email");
              } catch (error) {
                window.alert("Error in  updating Email");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">Phone No:</label>
          <input
            type="number"
            value={userData.phone}
            onChange={(e) => handleUpdate("phone", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updatephone.php?username=${userData.username}&phone_no=${userData.phone}`
                );
                window.alert("Succesfully updated Phone Number");
              } catch (error) {
                window.alert("Error in  updating Phone Number");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">Pin Code:</label>
          <input
            type="number"
            value={userData.pinCode}
            onChange={(e) => handleUpdate("pinCode", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updateaddress.php?username=${
                    userData.username
                  }&address=${
                    userData.pinCode +
                    "," +
                    userData.city +
                    "," +
                    userData.state +
                    "," +
                    userData.country
                  }`
                );
                window.alert("Succesfully updated Address");
              } catch (error) {
                window.alert("Error in  updating Address");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">City</label>
          <input
            type="text"
            value={userData.city}
            onChange={(e) => handleUpdate("city", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updateaddress.php?username=${
                    userData.username
                  }&address=${
                    userData.pinCode +
                    "," +
                    userData.city +
                    "," +
                    userData.state +
                    "," +
                    userData.country
                  }`
                );
                window.alert("Succesfully updated Address");
              } catch (error) {
                window.alert("Error in  updating Address");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">State:</label>
          <input
            type="text"
            value={userData.state}
            onChange={(e) => handleUpdate("state", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updateaddress.php?username=${
                    userData.username
                  }&address=${
                    userData.pinCode +
                    "," +
                    userData.city +
                    "," +
                    userData.state +
                    "," +
                    userData.country
                  }`
                );
                window.alert("Succesfully updated Address");
              } catch (error) {
                window.alert("Error in  updating Address");
              }
            }}
          />
        </div>
        <div>
          <label htmlFor="">Country</label>
          <input
            type="text"
            value={userData.country}
            onChange={(e) => handleUpdate("country", e.target.value)}
            required
          />
          <input
            type="button"
            value="Update"
            className="update"
            onClick={() => {
              try {
                axios.get(
                  `${apiUrl}updateaddress.php?username=${
                    userData.username
                  }&address=${
                    userData.pinCode +
                    "," +
                    userData.city +
                    "," +
                    userData.state +
                    "," +
                    userData.country
                  }`
                );
                window.alert("Succesfully updated Address");
              } catch (error) {
                window.alert("Error in  updating Address");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Settings;
