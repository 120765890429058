import React, { useState } from "react";
import axios from "axios";
import Loader from "./Loader";

function Enquiry() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    organization: "",
    inquiryType: "",
    description: "",
    preferredContact: "",
    availability: "",
    additionalInfo: "",
    hearAboutUs: "",
    receiveNewsletter: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Send email to user
      await sendEmail(
        formData.email,
        "Thank you for your inquiry!",
        `
        <p>Dear ${formData.name},</p>
        <p>We have received your inquiry and will get back to you shortly.</p>
        <p>Thank you for reaching out to us!</p>
      `
      );

      // Send email to admin
      await sendEmail(
        process.env.REACT_APP_ADMIN_EMAIL,
        "New Inquiry Form Submission",
        `
        <h1>New Inquiry Form Submission</h1>
        <p><strong>Name:</strong> ${formData.name}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Phone Number:</strong> ${formData.phoneNumber}</p>
        <p><strong>Organization/Company:</strong> ${formData.organization}</p>
        <p><strong>Type of Inquiry:</strong> ${formData.inquiryType}</p>
        <p><strong>Brief Description of Inquiry:</strong> ${formData.description}</p>
        <p><strong>Preferred Method of Contact:</strong> ${formData.preferredContact}</p>
        <p><strong>Availability for Consultation:</strong> ${formData.availability}</p>
        <p><strong>Additional Information or Questions:</strong> ${formData.additionalInfo}</p>
        <p><strong>How did you hear about us?:</strong> ${formData.hearAboutUs}</p>
        <p><strong>Receive Newsletter:</strong> ${formData.receiveNewsletter}</p>
      `
      );

      setIsSubmitting(false);
      setSubmissionMessage("Mail sent successfully!");
      alert("Mail sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false);
      setSubmissionMessage("An error occurred while sending the email.");
    }
  };

  const sendEmail = async (receiverMail, subject, body) => {
    try {
      const response = await axios.post(process.env.REACT_APP_MAIL_API, {
        receiverMail,
        subject,
        body,
      });

      if (response.status !== 200) {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="Enquiry">
      {isSubmitting && <Loader />}
      <h1>Inquiry Form</h1>
      <div className="form">
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            required
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="organization">Organization/Company</label>
          <input
            type="text"
            id="organization"
            name="organization"
            onChange={handleChange}
          />
        </div>
        <div id="message">
          <label>Type of Inquiry</label>
          <div>
            <input
              type="radio"
              id="genInquiry"
              name="inquiryType"
              value="General Inquiry"
              onChange={handleChange}
            />
            <label htmlFor="genInquiry">General Inquiry</label>
            <input
              type="radio"
              id="consultation"
              name="inquiryType"
              value="Consultation"
              onChange={handleChange}
            />
            <label htmlFor="consultation">Consultation</label>
            <input
              type="radio"
              id="request"
              name="inquiryType"
              value="Request"
              onChange={handleChange}
            />
            <label htmlFor="request">Request</label>
            <input
              type="radio"
              id="other"
              name="inquiryType"
              value="Other"
              onChange={handleChange}
            />
            <label htmlFor="other">Other</label>
          </div>
          <label htmlFor="description">Brief Description of Inquiry</label>
          <textarea
            id="description"
            name="description"
            cols="30"
            rows="10"
            onChange={handleChange}
          ></textarea>
          <label>Preferred Method of Contact</label>
          <div>
            <input
              type="radio"
              id="preferredContactEmail"
              name="preferredContact"
              value="Email"
              onChange={handleChange}
            />
            <label htmlFor="preferredContactEmail">Email</label>
            <input
              type="radio"
              id="preferredContactPhone"
              name="preferredContact"
              value="Phone"
              onChange={handleChange}
            />
            <label htmlFor="preferredContactPhone">Phone</label>
          </div>
          <label htmlFor="availability">Availability for Consultation</label>
          <textarea
            id="availability"
            name="availability"
            cols="30"
            rows="5"
            onChange={handleChange}
          ></textarea>
          <label htmlFor="additionalInfo">
            Additional Information or Questions
          </label>
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            cols="30"
            rows="10"
            onChange={handleChange}
          ></textarea>
          <label htmlFor="howdidyouhearaboutus">
            How did you hear about us?
          </label>
          <textarea
            id="howdidyouhearaboutus"
            name="hearAboutUs"
            cols="30"
            rows="10"
            onChange={handleChange}
          ></textarea>
          <label>
            Would you like to receive updates and newsletters from us?
          </label>
          <div>
            <input
              type="radio"
              id="receiveNewsletterYes"
              name="receiveNewsletter"
              value="Yes"
              onChange={handleChange}
            />
            <label htmlFor="receiveNewsletterYes">Yes</label>
            <input
              type="radio"
              id="receiveNewsletterNo"
              name="receiveNewsletter"
              value="No"
              onChange={handleChange}
            />
            <label htmlFor="receiveNewsletterNo">No</label>
          </div>
          <p>
            By submitting this form, you agree to our Privacy Policy and Terms
            of Service.
          </p>
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </div>
      {submissionMessage && <p>{submissionMessage}</p>}
    </div>
  );
}

export default Enquiry;
