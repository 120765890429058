import React from "react";

function SpanBrandProtection() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return (
    <>
      {" "}
      <div className="templatebrd">
        <h2>SPAN SUITE/ BRAND PROTECTION</h2>
        <h1>BRAND PROTECTION</h1>
      </div>
      <main
        className="container"
        style={{
          padding: "10vh 20px",
          fontFamily: '"Nunito Sans", sans-serif',
        }}
      >
        <div className="p-5">
          <style
            dangerouslySetInnerHTML={{
              __html:
                "        .cdiv a {            text-decoration: none;        }    ",
            }}
          />
          <div className="cdiv">
            <p />
            <p className="ptitle">
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>
                  Brand Protection - Total Legal and Business Solutions (TLBS)
                </strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd1">IP Protection </a>
              </li>
              <li>
                <a href="#cd2">Revenue Protection </a>
              </li>
              <li>
                <a href="#cd3">Trust Protection </a>
              </li>
              <li>
                <a href="#cd4">Channel Protection </a>
              </li>
            </ul>
            <p />
            <p className="ptitle">
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>The Foundational Principles </strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd5">
                  Identifying infringer as an unseen competition
                </a>
              </li>
              <li>
                <a href="#cd6">
                  Emphasizing prevention, proactivity and strategy
                </a>
              </li>
              <li>
                <a href="#cd7">
                  Creating and relying on performance metrics and using data and
                  analysis to assess and mitigate risk
                </a>
              </li>
              <li>
                <a href="#cd8">
                  Highlighting the value of internal and external controls and
                  mechanisms for detecting and responding to infringement
                </a>
              </li>
              <li>
                <a href="#cd9">
                  Creating a culture of continuous improvement and embracing
                  learning{" "}
                </a>
              </li>
              <li>
                <a href="#cd10">
                  Promoting a holistic approach that integrates and coordinates
                  all parts of the firm for brand protection{" "}
                </a>
              </li>
              <li>
                <a href="#cd11">
                  Respond decisively the first time, every time
                </a>
              </li>
              <li>
                <a href="#cd12">Communicate and collaborate </a>
              </li>
            </ul>
            <p />
            <p
              style={{
                textAlign: "center !important",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Approach</strong>
              </span>
            </p>
            <ul
              style={{
                listStyle: "disc",
              }}
            >
              <li>
                <a href="#cd13">Discover </a>
              </li>
              <li>
                <a href="#cd14">Prioritize </a>
              </li>
              <li>
                <a href="#cd15">Act </a>
              </li>
            </ul>
            <p />
            <p
              style={{
                textAlign: "center !important",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Benefits of Brand Protection</strong>
              </span>
            </p>
            <p>
              <strong>Companies </strong>
            </p>
            <ul
              style={{
                listStyle: "disc",
              }}
            >
              <li>
                <a href="#cd16">
                  Keep patients and consumers safe from physical harm{" "}
                </a>
              </li>
              <li>
                <a href="#cd17">Protect customers from fraud </a>
              </li>
              <li>
                <a href="#cd18">Revenue Enhancement </a>
              </li>
              <li>
                <a href="#cd19">Protect reputation </a>
              </li>
              <li>
                <a href="#cd20">Enhance legal efficiency </a>
              </li>
              <li>
                <a href="#cd21">Stay compliant </a>
              </li>
              <li>
                <a href="#cd22">Proactive</a>
              </li>
            </ul>
            <p></p>
            <p
              style={{
                textAlign: "center !important",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Tools</strong>
              </span>
            </p>

            <p></p>
            <p
              style={{
                textAlign: "center !important",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Brand Protection - Blue Print</strong>
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>
                  Governance, Value Creation and Program Management{" "}
                </strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd25">Framework and Structure</a>
              </li>
              <li>
                <a href="#cd26">Policy, standards and practices development</a>
              </li>
              <li>
                <a href="#cd27">
                  Organizational engagement /collaboration and External
                  stakeholder engagement{" "}
                </a>
              </li>
              <li>
                <a href="#cd28">
                  Risk Assessment and Global Standards/ Best Practices for Brand
                  Protection{" "}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#cd29">Legal Aspects of brand protection</a>
              </li>
              <li>
                <a href="#cd30">
                  Key Organizations and Resources For Brand Protection{" "}
                </a>
              </li>
              <li>
                <a href="#cd31">Talent and business partners</a>
              </li>
              <li>
                <a href="#cd32">Resource - Tools and Toolkits</a>
              </li>
              <li>
                <a href="#cd33">Risk-based customized solutions </a>
              </li>
              <li>
                <a href="#cd34">
                  Incidents and Investigations management tool with dashboards
                  and analytics – Click-IPR
                </a>
              </li>
              <li>
                <a href="#cd35">Project Management</a>
              </li>
            </ul>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>I. Assess </strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd36">Internal</a>
              </li>
              <li>
                <a href="#cd37">External</a>
              </li>
              <li>
                <a href="#cd38">Legitimate Vs. Illicit Supply Chains</a>
              </li>
            </ul>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>II. Protect</strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd39">Awareness and Education </a>
              </li>
              <li>
                <a href="#cd40">
                  Brand Protection Strategies for Managing Supply Chain
                  Relationships{" "}
                </a>
              </li>
              <li>
                <a href="#cd41">Supply Chain Integrity </a>
              </li>
              <li>
                <a href="#cd42">
                  Product Protection / Security - authentication features
                </a>
              </li>
              <li>
                <a href="#cd43">
                  IPR (TM, CR, Design and Patents Registration)
                </a>
              </li>
              <li>
                <a href="#cd44">Customs Recordal of IPR</a>
              </li>
              <li>
                <strong>Security</strong>
                <ul>
                  <li>
                    <a href="#cd45">Transportation</a>
                  </li>
                  <li>
                    <a href="#cd46">Facility</a>
                  </li>
                  <li>
                    <a href="#cd47">IS and IT</a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>III. Monitor</strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd48">
                  Prioritizing Brand Protection: Analytics And Risk Management{" "}
                </a>
              </li>
              <li>
                <a href="#cd49">Commercial Insights and Data Analytics</a>
              </li>
              <li>
                <a href="#cd50">
                  Market Intelligence: (online and offline) Identifying
                  Counterfeit Products{" "}
                </a>
              </li>
            </ul>
            <p>
              <strong>
                <span
                  style={{
                    fontSize: "16px",
                  }}
                >
                  IV. Respond
                </span>
              </strong>
            </p>
            <ul>
              <li>
                <a href="#cd51">
                  Anti-Counterfeiting Incident Management -{" "}
                  <strong>Incident intake, Response Management </strong>
                </a>
              </li>
              <li>
                <a href="#cd52">
                  Management and Enforcement of IPR / Trademark Rights{" "}
                </a>
              </li>
              <li>
                <a href="#cd53">Enforcement Strategy and execution plan </a>
              </li>
              <li>
                <a href="#cd54">Communication: Training and Strategies </a>
              </li>
              <li>
                <a href="#cd55">
                  Developing Networks and Counter-Intelligence Strategies for
                  Brand Protection{" "}
                </a>
              </li>
              <li>
                <a href="#cd56">Conducting Product Security Investigations</a>
              </li>
              <li>
                <a href="#cd57">Identifying a Brand Protection Team </a>
              </li>
            </ul>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>V. Influence, Collaborate and Engage</strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd58">
                  Collaborations and Partnership with Customs, Law Enforcement,
                  Industry bodies/ associations and Government Agencies/
                  regulatory bodies{" "}
                </a>
              </li>
              <li>
                <a href="#cd59">Consumer/ Patient Engagement </a>
              </li>
            </ul>
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>VI. Measure </strong>
              </span>
            </p>
            <ul>
              <li>
                <a href="#cd60">Metrics to measure success</a>
              </li>
              <li>
                <a href="#cd61">ROI Calculation</a>
              </li>
              <li>
                <a href="#cd62">KPI Development</a>
              </li>
            </ul>
          </div>
          <div className="page_dtl">
            <p
              style={{
                textAlign: "center",
              }}
            ></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>
                  Brand Protection - Total Legal and Business Solutions (TLBS)
                </strong>
              </span>
            </p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                <strong>{`{What | Where | When | Why | How}`}</strong>
              </span>
            </p>
            <ul>
              <li
                id="cd1"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>IP Protection – </strong>
                Regain control of your brand with advanced monitoring and
                prioritization tools and methodology that give you complete
                visibility of the threats you face and the tools and processes
                to mitigate them.
              </li>
              <li
                id="cd2"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Revenue Protection </strong>– Drive your revenue growth
                by removing unauthorized/illicit products from online and
                offline space, fake advertisements on media, social media or
                copycat websites can deceive well-intentioned consumers
              </li>
              <li
                id="cd3"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Trust Protection – </strong>
                Protect the trust consumers place in your brand and gain access
                to proven practices to prevent consumers and patients from being
                exposed to unauthorized, dangerous goods and harmful scams 
              </li>
              <li
                id="cd4"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Channel Protection – </strong>
                Get complete visibility of the threats to your customer journey
                across all channels, leveraging intelligence techniques,
                technology to identify and prioritize the key issues
              </li>
            </ul>
            <p
              style={{
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>The Foundational Principles </strong>
              </span>
            </p>
            <ul>
              <li
                id="cd5"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Identifying infringer as an unseen competition</strong>
              </li>
              <li
                id="cd6"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Emphasizing prevention, proactivity and strategy
                </strong>
              </li>
              <li
                id="cd7"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Creating and relying on performance metrics and using data and
                  analysis to assess and mitigate risk
                </strong>
              </li>
              <li
                id="cd8"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Highlighting the value of internal and external controls and
                  mechanisms for detecting and responding to infringement
                </strong>
              </li>
              <li
                id="cd9"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Creating a culture of continuous improvement and embracing
                  learning{" "}
                </strong>
              </li>
              <li
                id="cd10"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Promoting a holistic approach that integrates and coordinates
                  all parts of the firm for brand protection{" "}
                </strong>
              </li>
              <li
                id="cd11"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Respond decisively the first time, every time</strong>
              </li>
              <li
                id="cd12"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Communicate and collaborate </strong>
              </li>
            </ul>
            <p
              style={{
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Approach</strong>
              </span>
            </p>
            <ol>
              <li
                id="cd13"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Discover – </strong>
                Gather intelligence on infringement against your brands
              </li>
              <li
                id="cd14"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Prioritize - </strong>
                Prioritize the most significant threat to your brand and
                consumer by risk and where the greatest impact can be achieved
              </li>
              <li
                id="cd15"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Act – </strong>
                Take action against prioritized threats strategically and at
                scale – After identifying the high-value targets, you need to
                take swift action that has a lasting impact
              </li>
            </ol>
            <p
              style={{
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Benefits of Brand Protection</strong>
              </span>
            </p>
            <ul>
              <li
                id="cd16"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Keep patients and consumers safe from physical harm{" "}
                </strong>
              </li>
              <li
                id="cd17"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Protect customers from fraud </strong>
              </li>
              <li
                id="cd18"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Revenue Enhancement</strong>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Regaining lost market share
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Incremental sales
                  </li>
                </ul>
              </li>
              <li
                id="cd19"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Protect reputation </strong>
              </li>
              <li
                id="cd20"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Enhance legal efficiency </strong>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    <strong>​</strong>
                    Increased legal team efficiency
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Incremental legal compensation
                  </li>
                </ul>
              </li>
              <li
                id="cd21"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Stay compliant </strong>
              </li>
              <li
                id="cd22"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Prevent - </strong>
                use increased business intelligence to target infringing
                activity proactively
              </li>
            </ul>
            <p
              style={{
                textAlign: "center",
              }}
            ></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <strong>Brand Protection Programme - Blue Print</strong>
              </span>
            </p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>
                  Governance, Value Creation and Program Management{" "}
                </strong>
              </span>
            </p>
            <ul>
              <li
                id="cd25"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Framework and Structure</strong>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Dedicated Department Charter
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Program design, management and governance
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Risk policies and perspectives
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    KPI
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Central management with global oversight
                  </li>
                </ul>
              </li>
              <li
                id="cd26"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Policy, standards and practices development</strong>
              </li>
              <li
                id="cd27"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Organizational engagement /collaboration and External
                  stakeholder engagement{" "}
                </strong>
              </li>
              <li
                id="cd28"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Risk Assessment and Global Standards/ Best Practices for Brand
                  Protection{" "}
                </strong>
                <ul>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Requirement and implications for conducting a risk
                    assessment for counterfeit product
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Identify organization and industry-specific standards
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Describe the influence of geographic location on regulatory
                    compliance and customary practices
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Sizing the issue – market evaluation – process/tool
                  </li>
                  <li
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Illicit trade Root Cause Analysis and developing multi-year
                    tactics to mitigate brand protection risk
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li
                id="cd29"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Legal Aspects of Counterfeiting </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Explain the legal basis for identifying the violation of
                    product counterfeiting
                  </li>
                  <li>
                    Explore how to protect your brand against counterfeiting
                    through the use of trademark and other IPR rights
                  </li>
                  <li>
                    Combat counterfeiting through the effective use of
                    enforcement strategies
                  </li>
                </ul>
              </li>
              <li
                id="cd30"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Key Organizations and Resources For Brand Protection{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Describe the differences between regulatory, industry,
                    academic and professional law enforcement organizations and
                    provide contact details of the relevant authorities in a
                    particular geography
                  </li>
                  <li>
                    Explain the roles and responsibilities of law enforcement
                    organizations and areas of speciality
                  </li>
                  <li>
                    Identify valuable resources for brand protection within the
                    organization
                  </li>
                </ul>
              </li>
              <li
                id="cd31"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Talent and Business partners </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Job Description / RFP drafting</li>
                  <li>Selection</li>
                  <li>Onboarding assistance</li>
                  <li>Trainings</li>
                </ul>
              </li>
              <li
                id="cd32"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Resources: Tools and Toolkits</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>supply chain risk assessment</li>
                  <li>brand protection risk assessment</li>
                  <li>brand protection toolkit</li>
                  <li>external manufacturer risk assessment</li>
                  <li>new business readiness risk assessment</li>
                  <li>supplier risk assessment,</li>
                  <li>product and packaging risk assessment</li>
                  <li>technology selection tool,</li>
                  <li>the legal expense optimization tool</li>
                  <li>legal procurement tool</li>
                  <li>market monitoring activity tool</li>
                  <li>customs awareness and enforcement tool</li>
                  <li>root cause analysis,</li>
                  <li>analytical/ correlation engine design</li>
                  <li>ROI calculation algorithm</li>
                  <li>
                    communications (internal and external stakeholders)
                    templates
                  </li>
                  <li>RACI</li>
                  <li>
                    external manufacturing / CMO risk assessment tool etc.
                  </li>
                  <li>Process mapping</li>
                  <li>Brand Protection in a Box</li>
                </ul>
              </li>
              <li
                id="cd33"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Risk-based customized solutions </strong>
              </li>
              <li
                id="cd34"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Incidents and Investigations management tool with dashboards
                  and analytics – Click-IPR
                </strong>
              </li>
              <li
                id="cd35"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Project Management</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Manage multi-stakeholder, regional and global, complex
                    projects and developing dashboards
                  </li>
                  <li>Project Management in a Box</li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>I. Assess </strong>
              </span>
            </p>
            <ul>
              <li
                id="cd36"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Internal</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Program Risk Assessment Processes and Supply Chain Mapping
                  </li>
                  <li>Product Level Risk Assessment / NPI / NPD</li>
                </ul>
              </li>
              <li
                id="cd37"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>External</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Illicit Trade supply chain mapping</li>
                  <li>Legal and Regulatory landscape analysis</li>
                  <li>Identifying other Brand Protection Laws</li>
                </ul>
              </li>
              <li
                id="cd38"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Legitimate Vs. Illicit Supply Chains</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Define segments of the supply chain from raw materials to
                    product placement and understand the possible risk to the
                    brand at each point
                  </li>
                  <li>
                    Demonstrate relationships between illicit and legitimate
                    supply chains, including diversion, gray market,
                    unauthorized production, theft, return fraud, and
                    repackaging
                  </li>
                  <li>
                    Recommend ways and methods to protect and secure the supply
                    chain
                  </li>
                </ul>
              </li>
            </ul>
            <p />
            <p>
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>II. Protect</strong>
              </span>
            </p>
            <ul>
              <li
                id="cd39"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Awareness and Education </strong>
              </li>
              <li
                id="cd40"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Brand Protection Strategies for Managing Supply Chain
                  Relationships{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Explain the goals in selecting a partner</li>
                  <li>
                    Discuss creating the agreement or contract with conditions
                    that aid the brand owner, subject to legal guidance
                  </li>
                  <li>
                    Discuss the value in monitoring and auditing internal
                    processes and third-party partners
                  </li>
                  <li>
                    Developing an understanding/ landscape - healthcare industry
                    supply and distribution practices and understanding the
                    illicit product supply chain
                  </li>
                </ul>
              </li>
              <li
                id="cd41"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Supply Chain Integrity </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>External Manufacturers / CMO’s / TPO</li>
                  <li>Product Returns and Destruction</li>
                  <li>Procurement and Contracting</li>
                  <li>Distribution Compliance</li>
                  <li>Third-party supplier evaluation and selection</li>
                  <li>Risk assessment and audits</li>
                </ul>
              </li>
              <li
                id="cd42"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Product Protection / Security - authentication features{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Understand the Anti-Counterfeiting Tactics and Technologies
                  </li>
                  <li>
                    Identify strategic objectives of a brand protection program
                  </li>
                  <li>
                    Understand the key factors to consider in selecting the best
                    tactics for a specific product and problem
                  </li>
                  <li>
                    Use the technology selection tool for identifying the right
                    technology basis the risk scoring
                  </li>
                  <li>
                    Develop knowledge of the different brand protection
                    technologies
                  </li>
                  <li>
                    Example - Understanding customers need state
                    <ul>
                      <li>
                        conducting research, surveys and interviews to gain an
                        understanding of the clients' businesses
                      </li>
                      <li>analysing statistics/data</li>
                      <li>
                        detecting issues and investigating ways to resolve them
                      </li>
                      <li>
                        compiling and presenting information orally, visually
                        and in writing
                      </li>
                      <li>
                        making recommendations for using Digi-Tech /
                        authentication solutions (its deployment), improvement,
                        assist in implementing agreed solutions
                      </li>
                      <li>
                        developing and assisting in implementing solution set,
                        new procedures and or trainings.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Benefits
                    <ul>
                      <li>Case Management</li>
                      <li>Intelligence gathering</li>
                      <li>Case Linking</li>
                      <li>Faster QA evaluation of the samples</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                id="cd43"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>IPR (TM, CR, Design and Patents Registration)</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Recommendations</li>
                  <li>Registrations</li>
                  <li>Protection</li>
                  <li>Maintenance</li>
                  <li>
                    Innovative and cutting-edge technology and legal solutions
                  </li>
                </ul>
              </li>
              <li
                id="cd44"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Customs Recordal of IPR</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Recordation</li>
                  <li>
                    Awareness, sensitization and training programs for the
                    Customs authorities
                  </li>
                  <li>Seizure assistance</li>
                  <li>
                    Developing intelligence picture of the container movement –
                    identifying the trading routes
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li
                style={{
                  textAlign: "justify",
                }}
              >
                Security
              </li>
              <li
                id="cd45"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Transportation</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Audits</li>
                  <li>
                    Investigations and complete case management into incidents
                    of Theft in Transit or SC breaches like pilferage, damage
                  </li>
                  <li>
                    Audit, review, risk assessment of the 3PL and Transportation
                    service provider and certifications
                  </li>
                </ul>
              </li>
              <li>
                <strong>Facility</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Audits, Review, Risk Assessments of the Facilities and
                    Certifications
                  </li>
                  <li>Physical Security</li>
                  <li>Electronic/ Digital Security</li>
                  <li>
                    Investigations and complete case management into incidents
                    of Theft in Transit or SC breaches like pilferage, damage
                  </li>
                </ul>
              </li>
              <li
                id="cd47"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>IS and IT</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Audits and Risk Assessments</li>
                  <li>Digital Forensics</li>
                  <li>Forensic Investigations</li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>III. Monitor</strong>
              </span>
            </p>
            <ul>
              <li
                id="cd48"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Prioritizing Brand Protection: Analytics And Risk Management{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Develop and implement an internal process for resources
                    prioritization that responds to the identified risk of
                    counterfeit products and describe the implications
                  </li>
                  <li>
                    Advise what data to collect, analyse, and measure to reduce
                    the risk of counterfeit products
                  </li>
                  <li>
                    Execute risk assessment for counterfeit products and analyse
                    results
                  </li>
                </ul>
              </li>
              <li
                id="cd49"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Commercial Insights and Data Analytics  </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Complaints Monitoring (Customer care, Patient helpline,
                    Quality etc.,)
                  </li>
                  <li>Customs – Import and Export Data</li>
                  <li>Sales and marketing data evaluation</li>
                  <li>The process to capture market / commercial insights</li>
                  <li>Review of sales, marketing and industry data</li>
                </ul>
              </li>
              <li
                id="cd50"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Market Intelligence: (online and offline) Identifying
                  Counterfeit Products{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Develop relevant market structures and how counterfeit
                    products infiltrate them
                  </li>
                  <li>
                    Discuss how to protect against the presence of counterfeits,
                    help develop essential skills that can be used for the
                    gathering of information, and assess the problem before
                    taking action
                  </li>
                  <li>
                    How to utilize external and internal partners in detecting,
                    assessing, and acting against counterfeiters in the
                    marketplace
                  </li>
                  <li>Use OSINT, HUMINT, CORSINT for intelligence gathering</li>
                  <li>Online Brand Protection Program</li>
                  <li>Develop lead generation program</li>
                  <li>Rapid response to leads</li>
                  <li>
                    Examples
                    <ul>
                      <li>
                        Qualitative, Quantitative and Targeted Surveys, Buy
                        Programs, Mystery Shopping (internal and external
                        stakeholders including investigators and or consultants)
                      </li>
                      <li>
                        Online Monitoring of Market Places, Websites and social
                        media
                      </li>
                      <li>Social Listening</li>
                      <li>Estimating the extent of the issue</li>
                      <li>Intelligence gathering programs</li>
                      <li>Sharing industry best practices</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "108pt",
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>IV. Respond </strong>
              </span>
            </p>
            <ul>
              <li
                id="cd51"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Anti-Counterfeiting Incident Management  - </strong>
                <strong>Incident intake, Response Management </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Identify reliable sources of information, and determine how
                    to compile, characterize, and organize it
                  </li>
                  <li>
                    Evaluate incident-related data to identify company risks,
                    counterfeiting trends and patterns, and determine root
                    causes
                  </li>
                  <li>
                    Determine opportunities for enhancement of the current state
                  </li>
                  <li>
                    Document incidents and learnings, and communicate revisions
                    in processes and practices with information sources
                  </li>
                  <li>
                    Others
                    <ul>
                      <li>SOP, RACI, Timelines</li>
                      <li>Templates and Examples</li>
                      <li>
                        Sharing industry best practices and deployment plans
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                id="cd52"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Management and Enforcement of IPR / Trademark Rights{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Inform re the importance of a company's intellectual
                    property
                  </li>
                  <li>Identify strategies to manage intellectual property</li>
                  <li>
                    Discuss the importance of collaboration in the protection of
                    company trademarks
                  </li>
                  <li>
                    Identify strategies for managing intellectual property
                    globally, including in the EU, China, India, UAE
                  </li>
                </ul>
              </li>
              <li
                id="cd53"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Enforcement Strategy and execution plan </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Evaluate all the applicable laws for brand protection,
                    including but not limited to just IPR laws
                  </li>
                  <li>
                    Develop an enforcement strategy (mix of civil, criminal and
                    administrative) to meet legal and business goals and
                    objectives
                  </li>
                </ul>
              </li>
              <li
                id="cd54"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Communication: Training and Strategies </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Design an internal communications plan to raise brand
                    protection awareness of employees and strategic partners
                  </li>
                  <li>
                    Develop strategies for external strategic communication with
                    law enforcement, regulatory authorities, business partners,
                    and consumers
                  </li>
                  <li>Communication templates</li>
                </ul>
              </li>
              <li
                id="cd55"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Developing Networks and Counter-Intelligence Strategies for
                  Brand Protection{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Develop the criteria for identifying in-country partners
                    within a given industry and the elements of an in-country
                    threat analysis
                  </li>
                  <li>
                    Identify context-specific strategies for gathering
                    counter-intelligence data and understand why it is vital to
                    safeguard anti-counterfeiting strategies
                  </li>
                  <li>
                    Understand the value of benchmarking against other brands
                  </li>
                </ul>
              </li>
              <li
                id="cd56"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Conducting Product Security Investigations</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Understand the elements of an investigation process and
                    utilize basic investigation techniques for counterfeit
                    products
                  </li>
                  <li>
                    Describe how to build an investigation by integrating
                    identified elements of proof with the appropriate standard
                    of evidence
                  </li>
                  <li>
                    Identify the importance of reporting and lead generation and
                    explain how a data management system enhances the
                    investigation process
                  </li>
                  <li>
                    Generate performance metrics used to support investigation
                    activity and provide value to the brand owner
                  </li>
                </ul>
              </li>
              <li
                id="cd57"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Identifying a Brand Protection Team </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Identify roles of a brand protection team</li>
                  <li>
                    Use the RACI model to establish team member roles and
                    responsibilities
                  </li>
                  <li>
                    Discuss the contributions and support provided by the
                    internal champions
                  </li>
                  <li>
                    Develop a strategy to secure resources from stakeholders
                  </li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "54pt",
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>V. Influence, Collaborate and Engage</strong>
              </span>
            </p>
            <ul>
              <li
                id="cd58"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>
                  Collaborations and Partnership with Customs, Law Enforcement,
                  Industry bodies/ associations and Government Agencies/
                  regulatory bodies{" "}
                </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Develop an understanding of the jurisdictional differences
                    of law enforcement agencies and the laws they enforce
                  </li>
                  <li>
                    Build an effective network of law enforcement partners
                  </li>
                  <li>
                    Educate and train law enforcement about your brand and
                    products
                  </li>
                  <li>
                    Identify relevant laws and regulations – Lobby for
                    Anti-counterfeiting law
                  </li>
                  <li>Network with industry and professional groups</li>
                  <li>
                    Representation at the industry forums and engagement with
                    government authorities, new business development. Example
                    healthcare industry INTA, PSI, USABC, OPPI, FICCI, CII,
                    ASSOCHAM, UNIFAB, REACT, QBPC, AdvaMed etc.,
                  </li>
                  <li>Trainings, Webinar, Seminars etc.,</li>
                </ul>
              </li>
              <li
                id="cd59"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Consumer/ Patient Engagement </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Raise levels of awareness amongst Consumers and Patients
                  </li>
                  <li>Develop positive engagement plans</li>
                  <li>CORSINT</li>
                  <li>APP/ Portal/ Loyalty programs</li>
                </ul>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "72pt",
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <strong> VI. Measure </strong>
              </span>
            </p>
            <ul>
              <li
                id="cd60"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>Metrics to measure success </strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Brand Protection Financial Recovery Activities</li>
                  <li>Brand-Protection Financial Avoidance Activities</li>
                </ul>
              </li>
              <li
                id="cd61"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>ROI Calculation</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>Anti-Counterfeiting ROI Calculator</li>
                </ul>
              </li>
              <li
                id="cd62"
                style={{
                  textAlign: "justify",
                }}
              >
                <strong>KPI Development</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Group significant activities of the brand
                    protection/anti-counterfeiting team into
                  </li>
                  <li>
                    <strong>FOUNDATION</strong> – activities which support the
                    foundation of the programme, but are not directly related to
                    ‘operational’ investigation and enforcement.  Investment is
                    needed to ensure a strong foundation.
                  </li>
                  <li>
                    <strong>INCIDENTS</strong> – response to incidents,
                    verification and compliance with notification and reporting
                    requirements.
                  </li>
                  <li>
                    <strong>INTELLIGENCE </strong>– gathering intelligence,
                    conducting investigations, conducting analysis and
                    maintaining a secure database of information.
                  </li>
                  <li>
                    <strong>ENFORCEMENT </strong>– supporting administrative and
                    criminal actions, threatening and taking civil action,
                    conducting online takedowns
                  </li>
                  <li>
                    <strong>ENGAGEMENT</strong> – engaging in policy discussions
                    with legislators, law enforcement, regulatory authorities,
                    judiciary, health authorities and other public stakeholders,
                    directly or through trade associations; collaborating with
                    industry partners
                  </li>
                  <li>
                    <strong>AWARENESS</strong> – publishing consumer alerts,
                    delivering warning notices, conducting public and employee
                    awareness programmes
                  </li>
                  <li>
                    <strong>ALIGNMENT</strong> – identifying relevant internal
                    stakeholders, defining roles and responsibilities, engaging,
                    conducting training to reinforce ways of working, delivering
                    relevant information to stakeholders and conducting periodic
                    reviews
                  </li>
                  <li>
                    Each activity leads to outcomes and those outcomes lead to
                    impact. 
                  </li>
                  <li>
                    Ultimately, the activities are designed to minimising the{" "}
                    <strong>RISK </strong>
                    of adverse incidents and legal/regulatory liability.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
}

export default SpanBrandProtection;
