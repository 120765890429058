import React from "react";
import styled, { keyframes } from "styled-components";

const jumpAnimation = keyframes`
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
`;

const shadowAnimation = keyframes`
  0%, 100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
`;

const LoaderWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
`;

const LoaderElement = styled.div`
  width: 48px;
  height: 5px;
  background: #999;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: ${shadowAnimation} 0.5s linear infinite;
`;

const LoaderInner = styled.div`
  width: 100%;
  height: 100%;
  background: #e11d48;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: ${jumpAnimation} 0.5s linear infinite;
`;

const Loader = () => {
  return (
    <div className="Loader">
      <LoaderWrapper>
        <LoaderElement />
        <LoaderInner />
      </LoaderWrapper>
    </div>
  );
};

export default Loader;
