import React from "react";
import data from "./JSON Data/partners.json";
import { useParams } from "react-router-dom";

function Partnership() {
  const { section, subsection } = useParams();
  const cardsdata = data[section][subsection];
  const onecard = (ele, index) => {
    return (
      <div className="flip-card" key={`${index + subsection}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img key={`image${index + subsection}`} src={ele.src} alt="" />
          </div>
          <div className="flip-card-back">
            <p className="title">{ele.content}</p>
            <p>{ele.shortinfo}</p>
          </div>
        </div>
      </div>
    );
  };
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <div id="Partnership" key={subsection}>
      <div className="templatebrd">
        <h2>{cardsdata[0].breadcrumb}</h2>
        <h1>
          {" "}
          {cardsdata[0].breadcrumb
            .split("/")
            .map((item) => item.trim())
            .pop()}
        </h1>
      </div>
      <div className="partnershipcardsdiv">
        {cardsdata.map((imgs, index) => onecard(imgs, index))}
      </div>
    </div>
  );
}

export default Partnership;
