import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function AdminViewUser() {
  const history = useNavigate();
  const [data, setData] = useState(null);
  const { username } = useParams();
  const url = `${process.env.REACT_APP_API_URL}userfetch.php`;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.post(`${url}?username=${username}`);
        setData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [url, username]);

  useEffect(() => {
    if (data && data.email) {
      localStorage.setItem("SpanUserEmail", decodeAndDisplay(data.email));
    }
  }, [data]);

  const token = localStorage.getItem("spanAdminToken");

  const fetchAdmin = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}adminfetch.php?token=${token}`
      );
    } catch (error) {
      history("/login/admin");
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const decodeAndDisplay = (value) => {
    return value ? decodeURIComponent(value) : "N/A";
  };

  return (
    <>
      <div id="User">
        {data && (
          <div className="profile-div">
            <img
              src={
                data.profile_photo
                  ? data.profile_photo
                  : process.env.PUBLIC_URL + "/assets/images/profile_photo.webp"
              }
              alt=""
            />
            <div>
              <p>
                <span>Name:</span> {decodeAndDisplay(data.name)}
              </p>
              <p>
                <span>Username:</span> {decodeAndDisplay(data.username)}
              </p>
              <p>
                <span>Email:</span> {decodeAndDisplay(data.email)}
              </p>
              <p>
                <span>Address:</span> {decodeAndDisplay(data.address)}
              </p>
              <p>
                <span>Phone Number:</span> {decodeAndDisplay(data.phone_no)}
              </p>
            </div>
          </div>
        )}
        <div className="access-div">
          <div className="nav-container">
            <Link to={"checkmyfiles"}>Check Files</Link>
            <Link to={"status"}>Check Status</Link>
            <Link to={"contactus"}>Messages</Link>
            <Link to={"settings"}>Change Data</Link>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default AdminViewUser;
